/**
 * Color interpolation methods.
 *
 * @category Image Adapter
 */
export enum InterpolationMethod {
    /**
     * Average 4 nearest neighbours.
     */
    AVERAGE = 1,

    /**
     * Average 9 nearest neighbours.
     */
    AVERAGE_9 = 2,

    /**
     * Average 16 nearest neighbours.
     */
    AVERAGE_16 = 3,

    /**
     * Just return background color.
     */
    BACKGROUND = 4,

    /**
     * Triangular filter interpolation. (Not implemented, will throw NotImplemented exception)
     */
    BILINEAR = 5, // TODO: implement

    /**
     * Blend of nearest 1, 2 or 4 pixels. (Not implemented, will throw NotImplemented exception)
     */
    BLEND = 6, // TODO: implement

    /**
     * Catmull-Rom interpolation. (Not implemented, will throw NotImplemented exception)
     */
    CATROM = 7, // TODO: implement

    /**
     * Integer (floor) interpolation.
     */
    INTEGER = 8,

    /**
     * Triangular Mesh interpolation. (Not implemented, will throw NotImplemented exception)
     */
    MESH = 9, // TODO: implement

    /**
     * Nearest Neighbour Only. (Not implemented, will throw NotImplemented exception)
     */
    NEAREST = 10, // TODO: implement

    /**
     * Cubic Spline (blurred) interpolation. (Not implemented, will throw NotImplemented exception)
     */
    SPLINE = 11 // TODO: implement
}