/**
 * Binds supported browser image interfaces to corresponding factories for convenience.
 * @module
 * @internal
 */

import {Canvas} from "./Canvas";
import {bindImageAdapterFactory} from "./adaptersBindings";

if (typeof HTMLCanvasElement !== 'undefined') {
    bindImageAdapterFactory(HTMLCanvasElement, (canvas: HTMLCanvasElement): Canvas => new Canvas(canvas));

    // Suppose we are in browser so also bind factory for blob
    if (typeof Blob !== "undefined") {
        bindImageAdapterFactory(Blob, (blob: Blob): Promise<Canvas> => Canvas.createFromBlob(blob));
    }
}

if (typeof OffscreenCanvas !== 'undefined') {
    bindImageAdapterFactory(OffscreenCanvas, (canvas: OffscreenCanvas): Canvas => new Canvas(canvas));
}

if (typeof HTMLImageElement !== 'undefined') {
    bindImageAdapterFactory(HTMLImageElement, (img: HTMLImageElement): Canvas => Canvas.createFromImage(img));
}

if (typeof ImageBitmap !== 'undefined') {
    bindImageAdapterFactory(ImageBitmap, (img: ImageBitmap): Canvas => Canvas.createFromImage(img));
}