import {Color} from "./types/Color";
import {BrowserCanvas} from "./types/BrowserCanvas";
import {LensException} from "./exception";

/**
 * Blends two colors by given weights.
 *
 * @param color1 First color to blend.
 * @param color2 Second color to blend.
 * @param [weight1=0.5] First color's weight.
 * @param [weight2] Second color's weight. If not passed -- will be calculated as 1 - weight1.
 * @returns Color blending result.
 */
export function blendColors(
    color1: Color,
    color2: Color,
    weight1: number = 0.5,
    weight2: number|null = null
): Color {
    if (weight2 === null) {
        weight2 = 1 - weight1;
    }

    let result: Color = [0, 0, 0, 0];

    for (let i = 0; i < 4; i++) {
        result[i] = Math.round(color1[i] * weight1 + color2[i] * weight2);
    }

    return result;
}

/**
 * Creates canvas.
 *
 * @param width Canvas width.
 * @param height Canvas height.
 * @param [forceOnscreen=false] Force to create HTMLCanvasElement.
 * @returns HTMLCanvasElement or OffscreenCanvas.
 */
export function makeCanvas(width: number, height: number, forceOnscreen: boolean = false): BrowserCanvas {
    if (!isOffscreenCanvasSupported() || forceOnscreen) {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        return canvas;
    }

    return new OffscreenCanvas(width, height);
}

/**
 * Preloads image.
 *
 * @param src Image url/data-url.
 * @param [img=null] Use specified HTMLImageElement as preloader. Creates new if null.
 * @returns Promise resolving preloaded HTMLImageElement.
 */
export function preloadHtmlImage(src: string, img: HTMLImageElement|null = null): Promise<HTMLImageElement> {
    img = img || new Image();

    return new Promise((resolve, reject) => {
        img!.onload = () => {
            img!.onload = null;
            img!.onerror = null;
            resolve(img!);
        };

        img!.onerror = () => reject(new Error("Couldn't load image"));

        img!.src = src;
    });
}

/**
 * Converts canvas image to HTMLImageElement.
 *
 * @param canvas HTML5 canvas or OffscreenCanvas.
 * @param [img=null] Use given image as result.
 * @returns Preloaded image element.
 */
export function canvasToImg(canvas: BrowserCanvas, img: HTMLImageElement|null = null): Promise<HTMLImageElement> {
    if (canvas instanceof HTMLCanvasElement) {
        return preloadHtmlImage(canvas.toDataURL(), img);
    } else {
        const domCanvas = makeCanvas(canvas.width, canvas.height, true) as HTMLCanvasElement;
        domCanvas.getContext('2d')!.drawImage(canvas, 0, 0);
        return preloadHtmlImage(domCanvas.toDataURL(), img);
    }
}

/**
 * Converts canvas contents to image blob.
 *
 * @param canvas Browser canvas.
 * @param [type] Optional image type. Defaults to png.
 * @param [quality] Optional image quality.
 */
export function canvasToBlob(canvas: BrowserCanvas, type?: string, quality?: number): Promise<Blob> {
    if (canvas instanceof HTMLCanvasElement) {
        return new Promise<Blob>(resolve => canvas.toBlob(resolve as BlobCallback, type, quality));
    } else {
        return canvas.convertToBlob({type, quality});
    }
}

/**
 * Checks for OffscreenCanvas support.
 */
export function isOffscreenCanvasSupported(): boolean {
    return typeof OffscreenCanvas !== "undefined";
}

/**
 * Returns canvas 2d rendering context.
 *
 * @param canvas
 * @throws {@link LensException} When can't get context.
 */
export function getCanvas2dContext(canvas: BrowserCanvas): CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D {
    const context = canvas.getContext('2d');

    if (context) {
        return context;
    }

    throw new LensException("Couldn't get canvas context 2d.");
}

/**
 * Converts OffscreenCanvas object to HTMLCanvasElement
 *
 * @param offscreen Offscreen canvas.
 * @return HTML Canvas.
 */
export function offscreenCanvasToCanvasElement(offscreen: OffscreenCanvas): HTMLCanvasElement {
    const html = document.createElement('canvas');
    html.width = offscreen.width;
    html.height = offscreen.height;
    getCanvas2dContext(html).drawImage(offscreen, 0, 0);
    return html;
}

/**
 * Converts HTMLCanvasElement to OffscreenCanvas.
 *
 * @param html HTML Canvas.
 * @return Offscreen canvas.
 */
export function canvasElementToOffscreenCanvas(html: HTMLCanvasElement): OffscreenCanvas {
    if ('transferControlToOffscreen' in html) {
        return html.transferControlToOffscreen();
    }
    const offscreen = new OffscreenCanvas(html.width, html.height);
    getCanvas2dContext(offscreen).drawImage(html, 0, 0);
    return offscreen;
}