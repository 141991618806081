/**
 * Converts degrees to radians.
 *
 * @param degrees Angle in degrees.
 * @returns Angle in radians.
 *
 * @category Math
 */
export function degreesToRadians(degrees: number): number {
    return Math.PI * degrees / 180;
}