import {EPSILON} from "../math/constants";
import {ResampleFilter} from "./ResampleFilter";
import {WeightingFunction} from "../weightingFunction";

/**
 * Resample filter.
 *
 * @see {@link https://www.imagemagick.org/Usage/filter/#filter Resampling Filters} at ImageMagick docs
 * @see {@link https://www2.eecs.berkeley.edu/Pubs/TechRpts/1989/CSD-89-516.pdf Fundamentals of Texture Mapping and Image Warping by Paul S. Heckbert}
 * page 41, section 3.4, 3.5
 * @see {@link https://imagemagick.org/api/MagickCore/resize_8c_source.html#l00757 AquireResizeFilter at ImageMagick source}
 *
 * @category Resample Filter
 */
export class DefaultResampleFilter implements ResampleFilter {
    /**
     * Filter region of support - the filter support limit.
     */
    private readonly support: number;

    /**
     * X-scale (blur-sharpen).
     */
    private readonly blur: number;

    /**
     * Dimension scaling to fit window support (usually 1.0).
     */
    private readonly scale: number;

    /**
     * Window support, usually equal to support (expert only).
     */
    private readonly windowSupport: number;

    private readonly filterFunction: WeightingFunction;
    private readonly windowingFunction: WeightingFunction;

    /**
     * @param filterFunction Filtering function.
     * @param windowingFunction Windowing function.
     * @param support Filter region of support - the filter support limit.
     * @param scale Dimension scaling to fit window support (usually 1.0).
     * @param [blur=1] X-scale (blur-sharpen).
     * @param [windowSupport=null] Window support, usually equal to support (expert only).
     */
    constructor(
        filterFunction: WeightingFunction,
        windowingFunction: WeightingFunction,
        support: number,
        scale: number,
        blur: number = 1,
        windowSupport: number|null = null
    ) {
        this.filterFunction    = filterFunction;
        this.windowingFunction = windowingFunction;
        this.support           = support;
        this.scale             = scale;
        this.blur              = blur;
        this.windowSupport     = windowSupport !== null ? windowSupport : support;
    }

    /**
     * @inheritDoc
     */
    getWorkingSupport(): number {
        return this.support * this.blur;
    }

    /**
     * @inheritDoc
     */
    getWeight(x: number): number {
        const xBlur = x / this.blur;
        const scale = xBlur < EPSILON ? 1 : this.window(this.scale * xBlur);
        return scale * this.filter(xBlur);
    }

    /**
     * @param x
     */
    private filter(x: number): number {
        return this.filterFunction(x, this.support, this.windowSupport);
    }

    /**
     *
     * @param x
     */
    private window(x: number): number {
        return this.windowingFunction(x, this.support, this.windowSupport);
    }
}