import {ReversePixelMapper} from "./ReversePixelMapper";

/**
 * Checks if object implements ReversePixelMapper interface.
 *
 * @param obj
 *
 * @category Reverse Pixel Mapper
 */
export function isReversePixelMapper(obj: any): obj is ReversePixelMapper {
    return typeof obj === 'object' && obj !== null &&
        'reverseMap' in obj && typeof obj.reverseMap === 'function' &&
        'getValidity' in obj && typeof obj.getValidity === 'function';
}