import {FilterPreset} from "./FilterPreset";

/**
 * Type guard fot filter preset.
 *
 * @param obj
 *
 * @category Resample Filter
 */
export function isFilterPreset(obj: any): obj is FilterPreset {
    return typeof obj === 'object' && obj !== null &&
        'filterFunctionFactory' in obj && typeof obj.filterFunctionFactory === 'string' &&
        'filterFunctionFactoryArgs' in obj && Array.isArray(obj.filterFunctionFactoryArgs) &&
        'windowFunctionFactory' in obj && typeof obj.windowFunctionFactory === 'string' &&
        'windowFunctionFactoryArgs' in obj && Array.isArray(obj.windowFunctionFactoryArgs) &&
        'support' in obj && typeof obj.support === 'number' &&
        'scale' in obj && typeof obj.scale === 'number';
}