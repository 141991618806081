import {EPSILON, M_2PI, M_PI2} from "../../math/constants";
import {Viewport} from '../../image/Viewport';
import {ReversePixelMapperWithBestFit} from "../ReversePixelMapperWithBestFit";
import {ReversePixelMapperWithEwaSupport} from "../ReversePixelMapperWithEwaSupport";

/**
 * Arc distortion.
 * _Note the coefficients use a center angle, so asymptotic join is
 * furthest from both sides of the source image. This also means that
 * for arc angles greater than 360 the sides of the image will be
 * trimmed equally._
 *
 * @see {@link https://www.imagemagick.org/Usage/distorts/#arc Arc distortion details} at ImageMagick docs.
 * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l02561 Arc distortion} at ImageMagick
 * source.
 * @category Reverse Pixel Mapper
 */
export class Arc implements ReversePixelMapperWithEwaSupport, ReversePixelMapperWithBestFit {
    /**
     * @inheritDoc
     */
    readonly hasConstantPartialDerivatives: false;

    /**
     * @inheritDoc
     */
    readonly forceBestFit: true;

    /**
     * Angle for center of source image.
     */
    readonly c0: number;

    /**
     * Angle scale for mapping to source image.
     */
    readonly c1: number;

    /**
     * Radius for top of source image.
     */
    readonly c2: number;

    /**
     * Radius scale for mapping source image.
     */
    readonly c3: number;

    /**
     * Center line of arc within source image.
     */
    readonly c4: number;

    /**
     * Angle to width ratio.
     */
    private readonly angleToWidth: number;

    /**
     * Radius to height ratio.
     */
    private readonly radiusToHeight: number;

    /**
     * Source image viewport.
     */
    private viewport: Viewport;


    /**
     * @param viewport Source image viewport.
     * @param c0 Angle for center of source image.
     * @param c1 Angle scale for mapping to source image.
     * @param c2 Radius for top of source image.
     * @param c3 Radius scale for mapping source image.
     * @param c4 Center line of arc within source image.
     */
    constructor(
        viewport: Viewport,
        c0: number,
        c1: number,
        c2: number,
        c3: number,
        c4: number
    ) {
        this.viewport = viewport;
        this.c0 = c0;
        this.c1 = c1;
        this.c2 = c2;
        this.c3 = c3;
        this.c4 = c4;

        /*
         * Convert the angle_to_width and radius_to_height
         * to appropriate scaling factors, to allow faster processing
         * in the mapping function.
         */
        this.angleToWidth = M_2PI * this.viewport.getWidth() / this.c1;
        this.radiusToHeight = this.viewport.getHeight() / this.c3;

        this.hasConstantPartialDerivatives = false;
        this.forceBestFit = true;
    }

    /**
     * @inheritDoc
     */
    reverseMap(x: number, y: number): [number, number] {
        let [u, v] = this.getUV(x, y);

        // now scale the angle and radius for source image lookup point
        u = u * this.angleToWidth + this.c4 + this.viewport.x1 + 0.5;
        v = (this.c2 - v) * this.radiusToHeight + this.viewport.y1;

        //console.log(u, v, x, y);

        return [u, v];
    }

    /**
     * @inheritDoc
     */
    getValidity(x: number, y: number): 1 {
        return 1;
    }

    /**
     * @inheritDoc
     */
    getPartialDerivatives(x: number, y: number): [number, number, number, number] {
        let [u, v] = this.getUV(x, y);

        /*
         * Arc Distortion Partial Scaling Vectors
         * Are derived by mapping the perpendicular unit vectors
         * dR  and  dA*R*2PI  rather than trying to map dx and dy
         * The results is a very simple orthogonal aligned ellipse.
         */
        if (v > EPSILON) {
            return [this.angleToWidth / (M_2PI * v), 0, 0, this.radiusToHeight];
        } else {
            return [this.viewport.getWidth() * 2, 0, 0, this.radiusToHeight];
        }
    }

    /**
     * @inheritDoc
     */
    getBestFitViewport(viewport: Viewport): Viewport {
        // Forward Map Corners
        let a = this.c0 - this.c1 / 2,
            ca = Math.cos(a),
            sa = Math.sin(a),
            x = this.c2 * ca,
            y = this.c2 * sa,
            vp = new Viewport(x, y, x, y);

        x = (this.c2 - this.c3) * ca;
        y = (this.c2 - this.c3) * sa;
        vp.expand(x, y);

        a = this.c0 + this.c1 / 2;
        ca = Math.cos(a);
        sa = Math.sin(a);
        x = this.c2 * ca;
        y = this.c2 * sa;
        vp.expand(x, y);

        x = (this.c2 - this.c3) * ca;
        y = (this.c2 - this.c3) * sa;
        vp.expand(x, y);

        // Orthogonal points along top of arc
        for (
            a = Math.ceil((this.c0 - this.c1 / 2) / M_PI2) * M_PI2;
            a < this.c0 + this.c1 / 2;
            a += M_PI2
        ) {
            ca = Math.cos(a);
            sa = Math.sin(a);
            x = this.c2 * ca;
            y = this.c2 * sa;
            vp.expand(x, y);
        }

        vp.fixBounds();

        return vp;
    }

    /**
     * @param x
     * @param y
     */
    private getUV(x: number, y: number): [number, number] {
        let u, v;

        // what is the angle and radius in the destination image
        u = (Math.atan2(y, x) - this.c0) / M_2PI;
        u -= Math.round(u);
        v = Math.hypot(x, y);

        return [u, v];
    }
}