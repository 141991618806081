/**
 * Built-in filter presets names.
 *
 * @category Resample Filter
 */
export enum FilterName {
    /**
     * * Filter function: Cubic BC; B = 0.37821575509399867, C = 0.31089212245300067.
     * * Window function: Box.
     * * Support: 2.
     * * Scale: 1.1685777620836932.
     * * Blur: 1.
     */
    ROBIDOUX = 'Robidoux',

    /**
     * * Filter function: Cubic BC; B = 0.2620145123990142, C = 0.3689927438004929.
     * * Window function: Box.
     * * Support: 2.
     * * Scale: 1.105822933719019.
     * * Blur: 1.
     */
    ROBIDOUX_SHARP = 'RobidouxSharp',

    /**
     * * Filter function: Box.
     * * Window function: Box.
     * * Support: 0.5.
     * * Scale: 0.5.
     * * Blur: 1
     */
    BOX = 'Box',

    /**
     * Not implemented.
     */
    TRIANGLE = 'Triangle', // TODO: implement

    /**
     * Not implemented.
     */
    HERMITE = 'Hermite', // TODO: implement

    /**
     * Not implemented.
     */
    HANN = 'Hann', // TODO: implement

    /**
     * Not implemented.
     */
    HAMMING = 'Hamming', // TODO: implement

    /**
     * Not implemented.
     */
    BLACKMAN = 'Blackman', // TODO: implement

    /**
     * Not implemented.
     */
    GAUSSIAN = 'Gaussian', // TODO: implement

    /**
     * Not implemented.
     */
    QUADRATIC = 'Quadratic', // TODO: implement

    /**
     * Not implemented.
     */
    CUBIC = 'Cubic', // TODO: implement

    /**
     * Not implemented.
     */
    CATROM = 'Catrom', // TODO: implement

    /**
     * Not implemented.
     */
    MITCHELL = 'Mitchell', // TODO: implement

    /**
     * Not implemented.
     */
    JINC = 'Jinc', // TODO: implement

    /**
     * Not implemented.
     */
    SINC = 'Sinc', // TODO: implement

    /**
     * Not implemented.
     */
    SINC_FAST = 'SincFast', // TODO: implement

    /**
     * Not implemented.
     */
    KAISER = 'Kaiser', // TODO: implement

    /**
     * Not implemented.
     */
    WELCH = 'Welch', // TODO: implement

    /**
     * Not implemented.
     */
    PARZEN = 'Parzen', // TODO: implement

    /**
     * Not implemented.
     */
    BOHMAN = 'Bohman', // TODO: implement

    /**
     * Not implemented.
     */
    BARTLETT = 'Bartlett', // TODO: implement

    /**
     * Not implemented.
     */
    LAGRANGE = 'Lagrange', // TODO: implement

    /**
     * Not implemented.
     */
    LANCZOS = 'Lanczos', // TODO: implement

    /**
     * Not implemented.
     */
    LANCZOS_SHARP = 'LanczosSharp', // TODO: implement

    /**
     * Not implemented.
     */
    LANCZOS_2 = 'Lanczos2', // TODO: implement

    /**
     * Not implemented.
     */
    LANCZOS_2_SHARP = 'Lanczos2Sharp', // TODO: implement

    /**
     * Not implemented.
     */
    COSINE = 'Cosine', // TODO: implement

    /**
     * Not implemented.
     */
    SPLINE = 'Spline', // TODO: implement

    /**
     * Not implemented.
     */
    LANCZOS_RADIUS = 'LanczosRadius', // TODO: implement

    /**
     * Not implemented.
     */
    CUBIC_SPLINE = 'CubicSpline' // TODO: implement
}