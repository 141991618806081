import {ResampleFilter} from "./ResampleFilter";

/**
 * Type guard for {@link ResampleFilter}
 *
 * @param obj
 *
 * @category Resample Filter
 */
export function isResampleFilter(obj: any): obj is ResampleFilter {
    return typeof obj === 'object' && obj !== null &&
        'getWorkingSupport' in obj && typeof obj.getWorkingSupport === 'function' &&
        'getWeight' in obj && typeof obj.getWeight === 'function';
}