import {LensException} from './LensException';

/**
 * Exception for unsolvable matrix in Gauss-Jordan elimination.
 *
 * @category Exception
 */
export class UnsolvableMatrix extends LensException {

    /**
     * @param message Error message.
     */
    constructor(message: string) {
        super(message);
        this.name = 'UnsolvableMatrix';
    }
}