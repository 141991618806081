/**
 * {@link https://en.wikipedia.org/wiki/Machine_epsilon Machine epsilon} used in calculations.
 *
 * @category Math
 */
export const EPSILON = Number.EPSILON === undefined ? Math.pow(2, -52) : Number.EPSILON;

/**
 * @category Math
 */
export const MAXIMUM_VALUE = Number.MAX_VALUE;

/**
 * Pi/2
 *
 * @category Math
 */
export const M_PI2 = Math.PI / 2;

/**
 * Pi * 2
 *
 * @category Math
 */
export const M_2PI = Math.PI * 2;