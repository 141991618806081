import {AbstractImageAdapter} from "./AbstractImageAdapter";
import {ImageAdapter} from "./ImageAdapter";
import {
    canvasToBlob,
    canvasToImg,
    getCanvas2dContext,
    canvasElementToOffscreenCanvas,
    isOffscreenCanvasSupported,
    makeCanvas,
    offscreenCanvasToCanvasElement,
    preloadHtmlImage
} from "../util";
import {BrowserCanvas} from "../types/BrowserCanvas";
import {Color} from "../types/Color";
import {InvalidMethodCall} from "../exception/InvalidMethodCall";

/**
 * ImageAdapter implementation for distortion using HTML5 Canvas objects.
 *
 * @category Image Adapter
 */
export class Canvas extends AbstractImageAdapter<Canvas, BrowserCanvas>
    implements ImageAdapter<Canvas, BrowserCanvas> {
    /**
     * Browser canvas (HTMLCanvasElement or OffscreenCanvas) with image resource.
     */
    protected canvas: BrowserCanvas;

    /**
     * ImageData object to work with.
     */
    protected imageData: ImageData;

    /**
     * ImageData.data array stored for faster access.
     */
    protected data: Uint8ClampedArray;

    /**
     * @param canvas HTML5 Canvas or offscreen canvas with image data.
     */
    constructor(canvas: BrowserCanvas) {
        super(canvas.width, canvas.height);
        this.canvas     = canvas;
        this.imageData  = getCanvas2dContext(canvas).getImageData(0, 0, canvas.width, canvas.height);
        this.data       = this.imageData.data;
    }

    /**
     * Creates new instance using CanvasImageSource.
     *
     * @param image Any CanvasImageSource.
     * @returns Canvas instance.
     */
    static createFromImage(image: Exclude<CanvasImageSource, SVGImageElement>|OffscreenCanvas): Canvas {
        const canvas = makeCanvas(image.width, image.height);
        getCanvas2dContext(canvas).drawImage(image, 0, 0);
        return new Canvas(canvas);
    }

    /**
     * Asynchronously creates Canvas instance from image url.
     * @param url Image url.
     * @returns Promise resolving new Canvas instance.
     */
    static createFromUrl(url: string): Promise<Canvas> {
        return preloadHtmlImage(url)
            .then(img => this.createFromImage(img));
    }

    /**
     * Asynchronously creates Canvas instance from Blob or File.
     * @param blob Image blob/file.
     * @returns Promise resolving new Canvas instance.
     */
    static createFromBlob(blob: Blob): Promise<Canvas> {
        return createImageBitmap(blob)
            .then(bitmap => this.createFromImage(bitmap));
    }

    /**
     * @inheritDoc
     */
    getImagePixelColor(x: number, y: number): Color {
        const offset = (y * this.width + x) * 4;

        return Array.prototype.slice.call(
            this.data,
            offset,
            offset + 4
        );
    }

    /**
     * @inheritDoc
     */
    setImagePixelColor(x: number, y: number, color: Color): void {
        const offset = (y * this.width + x) * 4;

        color.forEach((channel, i) => this.data[offset + i] = channel);
    }

    /**
     * @inheritDoc
     */
    getAverageColor(): Color {
        const canvas = makeCanvas(1, 1);
        const context = getCanvas2dContext(canvas);
        context.drawImage(this.canvas, 0, 0, this.width, this.height, 0, 0, 1, 1);
        return Array.prototype.slice.call(
            context.getImageData(0, 0, 1, 1).data
        );
    }

    /**
     * @inheritDoc
     */
    protected resize(width: number, height: number): Promise<Canvas> {
        const dst = makeCanvas(width, height);
        getCanvas2dContext(dst).drawImage(this.canvas, 0, 0, this.width, this.height, 0, 0, width, height);
        return Promise.resolve(new Canvas(dst));
    }

    /**
     * @inheritDoc
     */
    getResource(): Promise<BrowserCanvas> {
        return Promise.resolve(this.canvas);
    }

    /**
     * Returns promise resolving html5 canvas node containing image.
     */
    getCanvasElement(): Promise<HTMLCanvasElement> {
        return this.getResource()
            .then(canvas => {
                if (canvas instanceof HTMLCanvasElement) {
                    return canvas;
                }
                return offscreenCanvasToCanvasElement(canvas);
            });
    }

    /**
     * Returns promise resolving OffscreenCanvas object containing image (if supported by environment).
     */
    getOffscreenCanvas(): Promise<OffscreenCanvas> {
        if (!isOffscreenCanvasSupported()) {
            throw new InvalidMethodCall(`Offscreen canvas is not supported in your environment.`);
        }

        return this.getResource()
            .then(canvas => {
                if (canvas instanceof OffscreenCanvas) {
                    return canvas;
                }
                return canvasElementToOffscreenCanvas(canvas);
            });
    }

    /**
     * Promise resolving HTMLImageElement node containing image.
     */
    getImageElement(): Promise<HTMLImageElement> {
        return this.getResource()
            .then(canvas => canvasToImg(canvas));
    }

    /**
     * Returns promise resolving image blob.
     *
     * @param [type] Optional image type. Defaults to png.
     * @param [quality] Optional image quality.
     */
    getBlob(type?: string, quality?: number): Promise<Blob> {
        return this.getResource()
            .then(canvas => canvasToBlob(canvas, type, quality));
    }

    /**
     * @inheritDoc
     */
    protected prepareBlank(width: number, height: number): Promise<Canvas> {
        return Promise.resolve(
            new Canvas(makeCanvas(width, height))
        );
    }

    /**
     * @inheritDoc
     */
    commit(): Promise<Canvas> {
        getCanvas2dContext(this.canvas).putImageData(this.imageData, 0, 0);
        return Promise.resolve(this);
    }
}