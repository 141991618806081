import {Arc} from "./Arc";
import {Viewport} from "../../image";
import {EPSILON, M_2PI, M_PI2} from "../../math/constants";
import {InvalidArgument} from "../../exception";
import {degreesToRadians} from "../../math/degreesToRadians";

/**
 * Creates arc distortion class from arguments.
 *
 * Arguments:  **[angle, rotation, outer_radius, inner_radius]**
 * All but first argument are optional.
 *
 * By default, if the radii arguments are nor provided the image radius
 * is calculated so the horizontal center-line is fits the given arc
 * without scaling.
 *
 * The output image size is ALWAYS adjusted to contain the whole image,
 * and an offset is given to position image relative to the 0,0 point of
 * the origin, allowing users to use relative positioning onto larger
 * background.
 *
 * The arguments are converted to distortion coefficients.
 *
 * @param viewport Source image viewport.
 * @param args Arguments:
 * * 0: **angle** - The angle over which to arc the image side-to-side.
 * * 1: **rotation** - Angle to rotate image from vertical center.
 * * 2: **outer_radius** - Set top edge of source image at this radius.
 * * 3: **inner_radius** - Set bottom edge to this radius (radial scaling).
 * @returns Arc instance.
 * @throws {@link InvalidArgument} Thrown when first (angle) or third (outer radius) arguments are too small.
 *
 * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l01095 Generating coefficients} for arc
 * distortion at ImageMagick source.
 * @category Reverse Pixel Mapper Factory
 */
export function ArcFactory(
    args: [number, number?, number?, number?],
    viewport: Viewport
): Arc {
    if (args.length >= 1 && args[0] < EPSILON) {
        throw new InvalidArgument('Angle too small');
    }

    if (args.length >= 3 && args[2] !== undefined && args[2] < EPSILON) {
        throw new InvalidArgument('Outer radius too small');
    }

    let c0, c1, c2, c3, c4;

    c0 = -M_PI2; // -90, place at top!

    if (args.length >= 1) {
        c1 = degreesToRadians(args[0]);
    } else {
        c1 = M_PI2; // zero arguments - center is at top
    }

    if (args.length >= 2 && args[1] !== undefined) {
        c0 += degreesToRadians(args[1]);
    }

    c0 /= M_2PI; // normalize radians
    c0 -= Math.round(c0);
    c0 *= M_2PI; // de-normalize back to radians

    c3 = viewport.getHeight() - 1;
    c2 = viewport.getWidth() / c1 + c3 / 2;

    if (args.length >= 3 && args[2] !== undefined && args[3] !== undefined) {
        if (args.length >= 4) {
            c3 = args[2] - args[3];
        } else {
            c3 *= args[2] / c2;
        }

        c2 = args[2];
    }

    c4 = (viewport.getWidth() - 1) / 2;

    return new Arc(viewport, c0, c1, c2, c3, c4);
}