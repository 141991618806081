import {ImageAdapter} from "./ImageAdapter";
import {Viewport} from "./Viewport";
import {VirtualPixelMethod} from "./VirtualPixelMethod";
import {InterpolationMethod} from "./InterpolationMethod";
import {Color} from "../types/Color";
import {NotImplemented} from "../exception/NotImplemented";

/**
 * Returns nearest edge coords.
 *
 * @internal
 * @param x X image coordinate.
 * @param y Y image coordinate.
 * @param width Image width.
 * @param height Image height.
 * @returns Image nearest edge coords.
 */
function getEdgeCoords(x: number, y: number, width: number, height: number): [number, number] {
    return [
        Math.max(0, Math.min(width - 1, x)),
        Math.max(0, Math.min(height - 1, y))
    ];
}

/**
 * Returns image coords like if image is repeated infinitely both vertically and horizontally. X and Y may be out of image
 * bounds.
 *
 * @internal
 * @param x X image coordinate.
 * @param y Y image coordinate.
 * @param width Image width.
 * @param height Image height.
 */
function getTileCoords(x: number, y: number, width: number, height: number): [number, number] {
    let rx = x % width,
        ry = y % height;

    return [
        rx < 0 ? width + rx : rx,
        ry < 0 ? height + ry : ry
    ];
}

/**
 * Abstract image class for ImageAdapter implementation.
 *
 * @category Image Adapter
 */
export abstract class AbstractImageAdapter<Concrete extends ImageAdapter<Concrete, ResourceType>, ResourceType = unknown>
    implements ImageAdapter<Concrete, ResourceType> {
    /**
     * Image width.
     */
    readonly width: number;

    /**
     * Image height.
     */
    readonly height: number;

    /**
     * Image virtual viewport.
     */
    protected viewport: Viewport;

    /**
     * Image background color.
     */
    protected backgroundColor: Color;

    /**
     * Image virtual pixel method.
     */
    protected virtualPixelMethod: VirtualPixelMethod;

    /**
     * Image quantum range (per channel).
     */
    protected quantumRange: number;

    /**
     * Image interpolation method.
     */
    protected interpolationMethod: InterpolationMethod;

    /**
     * @param width Image width.
     * @param height Image height.
     */
    protected constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
        this.viewport = new Viewport(0, 0, this.width - 1, this.height - 1);
        this.backgroundColor = [0, 0, 0, 0];
        this.virtualPixelMethod = VirtualPixelMethod.TRANSPARENT;
        this.quantumRange = 255;
        this.interpolationMethod = InterpolationMethod.INTEGER;
    }

    /**
     * Returns pixel color at given **real** image coords. Image coords MUST be: x ∈ [0; width - 1], y ∈ [0; height - 1].
     *
     * @param x X image coordinate.
     * @param y Y image coordinate.
     * @returns Pixel color at given coordinates.
     */
    abstract getImagePixelColor(x: number, y: number): Color;

    /**
     * Sets pixel color at given **real** image coordinates.
     *
     * @param x X image coordinate.
     * @param y Y image coordinate.
     * @param color Pixel color.
     */
    abstract setImagePixelColor(x: number, y: number, color: Color): any;

    /**
     * @inheritDoc
     */
    abstract getAverageColor(): Color;

    /**
     * Returns resized instance of self.
     *
     * @param width New width.
     * @param height New height.
     * @returns New resized instance.
     */
    protected abstract resize(width: number, height: number): Promise<Concrete>;

    /**
     * @inheritDoc
     */
    abstract getResource(): Promise<ResourceType>;

    /**
     * Prepares blank image for {@link ImageAdapter.getBlank} method.
     *
     * @param width Image width.
     * @param height Image height.
     */
    protected abstract prepareBlank(width: number, height: number): Promise<Concrete>;

    /**
     * @inheritDoc
     */
    abstract commit(): Promise<Concrete>;

    /**
     * @inheritDoc
     */
    getViewport(): Viewport {
        return this.viewport;
    }

    /**
     * @inheritDoc
     */
    setViewport(viewport: Viewport): this {
        this.viewport = viewport;
        return this;
    }

    /**
     * @inheritDoc
     */
    getBackgroundColor(): Color {
        return this.backgroundColor;
    }

    /**
     * @inheritDoc
     */
    setBackgroundColor(color: Color): this {
        this.backgroundColor = color;
        return this;
    }

    /**
     * @inheritDoc
     */
    getInterpolationMethod(): InterpolationMethod {
        return this.interpolationMethod;
    }

    /**
     * @inheritDoc
     */
    setInterpolationMethod(method: InterpolationMethod): this {
        if ([
            InterpolationMethod.BILINEAR,
            InterpolationMethod.BLEND,
            InterpolationMethod.CATROM,
            InterpolationMethod.MESH,
            InterpolationMethod.NEAREST,
            InterpolationMethod.SPLINE
        ].includes(method)) {
            throw new NotImplemented('Selected interpolation method is not implemented yet.');
        }

        this.interpolationMethod = method;
        return this;
    }

    /**
     * @inheritDoc
     */
    getVirtualPixelMethod(): VirtualPixelMethod {
        return this.virtualPixelMethod;
    }

    /**
     * @inheritDoc
     */
    setVirtualPixelMethod(method: VirtualPixelMethod): this {
        if (method === VirtualPixelMethod.CHECKER_TILE || method === VirtualPixelMethod.DITHER) {
            throw new NotImplemented('Selected virtual pixel method is not implemented yet.');
        }

        this.virtualPixelMethod = method;
        return this;
    }

    /**
     * @inheritDoc
     */
    getQuantumRange(): number {
        return this.quantumRange;
    }

    /**
     * @inheritDoc
     */
    getPixelColor(x: number, y: number): Color {
        x = Math.floor(x - this.viewport.x1);
        y = Math.floor(y - this.viewport.y1);

        if (x >= 0 && x < this.width && y >= 0 && y < this.height) {
            return this.getImagePixelColor(x, y);
        }

        return this.getVirtualPixelColor(x, y, this.virtualPixelMethod);
    }

    /**
     * @inheritDoc
     */
    setPixelColor(x: number, y: number, color: Color): this {
        x = Math.floor(x - this.viewport.x1);
        y = Math.floor(y - this.viewport.y1);

        if (x >= 0 && x < this.width && y >= 0 && y < this.height) {
            this.setImagePixelColor(x, y, color);
        }

        return this;
    }

    /**
     * @inheritDoc
     */
    getBlank(viewport: Viewport): Promise<Concrete> {
        return this.prepareBlank(viewport.getWidth(), viewport.getHeight())
            .then(blank => {
                return this.duplicateProps(
                    blank.setViewport(viewport)
                );
            });
    }

    /**
     * @inheritDoc
     */
    scale(scale: number): Promise<Concrete> {
        const viewport = this.viewport.clone();
        viewport.scale(scale);

        return this.resize(viewport.getWidth(), viewport.getHeight())
            .then(resized => {
                return this.duplicateProps(
                    resized.setViewport(viewport)
                );
            });
    }

    /**
     * Returns virtual pixel color.
     *
     * @param x Image X-coordinate OUTSIDE of image bounds.
     * @param y Image Y-coordinate OUTSIDE of image bounds.
     * @param method Virtual pixel method.
     * @returns Virtual pixel color.
     */
    protected getVirtualPixelColor(x: number, y: number, method: VirtualPixelMethod): Color {
        let qr;

        switch (method) {
            case VirtualPixelMethod.BACKGROUND:
                return this.backgroundColor;

            case VirtualPixelMethod.EDGE:
                return this.getImagePixelColor(...getEdgeCoords(x, y, this.width, this.height));

            case VirtualPixelMethod.MIRROR:
                let [tx, ty] = getTileCoords(x, y, this.width * 2, this.height * 2);

                if (tx > this.width - 1) {
                    tx = this.width - (tx - this.width) - 1;
                }

                if (ty > this.height - 1) {
                    ty = this.height - (ty - this.height) - 1;
                }

                return this.getImagePixelColor(tx, ty);

            case VirtualPixelMethod.TILE:
                return this.getImagePixelColor(...getTileCoords(x, y, this.width, this.height));

            case VirtualPixelMethod.TRANSPARENT:
            default:
                return [0, 0, 0, 0];

            case VirtualPixelMethod.BLACK:
                return [0, 0, 0, this.quantumRange];

            case VirtualPixelMethod.WHITE:
                qr = this.quantumRange;
                return [qr, qr, qr, qr];
            case VirtualPixelMethod.GRAY:
                qr = this.quantumRange;
                const halfQr = Math.round(qr / 2);
                return [halfQr, halfQr, halfQr, qr];

            case VirtualPixelMethod.HORIZONTAL_TILE:
            case VirtualPixelMethod.HORIZONTAL_TILE_EDGE:
                if (y < 0 || y >= this.height) {
                    return method === VirtualPixelMethod.HORIZONTAL_TILE ? this.backgroundColor
                        : this.getImagePixelColor(...getEdgeCoords(x, y, this.width, this.height));
                }

                return this.getImagePixelColor(...getTileCoords(x, y, this.width, this.height));

            case VirtualPixelMethod.VERTICAL_TILE:
            case VirtualPixelMethod.VERTICAL_TILE_EDGE:
                if (x < 0 || x >= this.width) {
                    return method === VirtualPixelMethod.VERTICAL_TILE ? this.backgroundColor
                        : this.getImagePixelColor(...getEdgeCoords(x, y, this.width, this.height));
                }

                return this.getImagePixelColor(...getTileCoords(x, y, this.width, this.height));

            case VirtualPixelMethod.RANDOM:
                return this.getImagePixelColor(
                    Math.floor(Math.random() * this.width),
                    Math.floor(Math.random() * this.height)
                );
        }
    }

    /**
     * @inheritDoc
     */
    getInterpolatedPixelColor(
        x: number,
        y: number,
        interpolationMethod: InterpolationMethod = this.interpolationMethod
    ): Color {
        switch (interpolationMethod) {
            case InterpolationMethod.AVERAGE:
                return this.interpolateAverage(x, y, 2);
            case InterpolationMethod.AVERAGE_9:
                return this.interpolateAverage(x, y, 3);
            case InterpolationMethod.AVERAGE_16:
                return this.interpolateAverage(x, y, 4);
            case InterpolationMethod.BACKGROUND:
                return this.backgroundColor;
            case InterpolationMethod.INTEGER:
            default:
                return this.getPixelColor(Math.floor(x), Math.floor(y));
            case InterpolationMethod.BILINEAR:
            case InterpolationMethod.BLEND:
            case InterpolationMethod.CATROM:
            case InterpolationMethod.MESH:
            case InterpolationMethod.NEAREST:
            case InterpolationMethod.SPLINE:
                throw new NotImplemented('Selected interpolation method is not implemented yet.');
        }
    }

    /**
     * Returns interpolated color by average of neighbors.
     *
     * @param x X coordinate.
     * @param y Y coordinate.
     * @param count Number of pixels (both vertically and horizontally) to take in account.
     */
    protected interpolateAverage(x: number, y: number, count: number = 2): Color {
        let startX, startY;

        switch (count) {
            case 2:
                startX = Math.floor(x);
                startY = Math.floor(y);
                break;

            case 3:
                startX = Math.floor(x + 0.5) - 1;
                startY = Math.floor(y + 0.5) - 1;
                break;
            case 4:
                startX = Math.floor(x) - 1;
                startY = Math.floor(y) - 1;
                break;

            default:
                throw new Error("Param 'count' must be integer between 2 and 4.");
        }

        const endX = startX + count;
        const endY = startY + count;
        let color = [0, 0, 0, 0];

        for (let u = startY; u < endY; u++) {
            for (let v = startX; v < endX; v++) {
                this.getPixelColor(u, v).forEach((channel, i) => color[i] += channel);
            }
        }

        const gamma = 1 / (count * count);

        return color.map(channel => Math.round(channel * gamma)) as Color;
    }

    /**
     * Used to copy some props in methods that returns new instance.
     *
     * @param instance Instance which props should be set.
     */
    protected duplicateProps(instance: Concrete): Concrete {
        return instance.setInterpolationMethod(this.interpolationMethod)
            .setVirtualPixelMethod(this.virtualPixelMethod)
            .setBackgroundColor(this.backgroundColor);
    }
}