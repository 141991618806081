import {EPSILON} from "./constants";

/**
 * Return 1/x where x is perceptible (not unlimited or infinitesimal).
 *
 * @param x Number
 * @see {@link https://imagemagick.org/api/MagickCore/pixel-accessor_8h_source.html#l00234 PerceptibleReciprocal()}
 * at ImageMagick source.
 *
 * @category Math
 */
export function perceptibleReciprocal(x: number): number {
    const sign = x < 0 ? -1 : 1;

    if (sign * x >= EPSILON) {
        return 1 / x;
    }

    return sign / EPSILON;
}