import {ImageAdapter} from "../image/ImageAdapter";
import {Viewport} from "../image/Viewport";
import {ReversePixelMapper} from "./ReversePixelMapper";
import {ColorResampler} from "../resampler/ColorResampler";
import {DistortionResult} from "../types/DistortionResult";
import {EllipticalWeightedAverage} from "../resampler/EllipticalWeightedAverage";
import {InvalidArgument} from "../exception/InvalidArgument";
import {isReversePixelMapperWithBestFit} from "./isReversePixelMapperWithBestFit";

/**
 * Distortion processor class. Uses reverse pixel mapping to process distortions.
 *
 * @category Reverse Pixel Mapper
 */
export class DistortionProcessor<ConcreteAdapter extends ImageAdapter<ConcreteAdapter>> {
    /**
     * Image being distorted.
     */
    private image: ImageAdapter<ConcreteAdapter>;

    /**
     * Pixel mapper used for distortion.
     */
    private readonly pixelMapper: ReversePixelMapper;

    /**
     * Color resampler.
     */
    private readonly resampler: ColorResampler;

    /**
     * Flag if best-fit viewport should be used.
     */
    private readonly bestFit: boolean;

    /**
     * Output scaling factor for super-sampling purpose.
     */
    private readonly outputScaling: number;

    /**
     * Time in milliseconds before further pixel processing will be delayed.
     */
    private readonly asyncTimeout: number;

    /**
     * When set to true, viewport offset will be reset to (0, 0) after distortion.
     * When set to [number, number], corresponding offset will be set to distorted image viewport.
     */
    private readonly repage: boolean|[number, number];

    /**
     * Specified distorted image viewport. If set to null and bestFit is false, original image viewport will be used.
     */
    private readonly viewport: Viewport|null;

    /**
     * Used to store start x coordinate for async processing loop.
     */
    private startX: number;

    /**
     * Used to store end x coordinate for async processing loop.
     */
    private endX: number;

    /**
     * Used to store end y coordinate for async processing loop.
     */
    private endY: number;

    /**
     * Used to store current x coordinate for async processing loop.
     */
    private currentX: number;

    /**
     * Used to store current y coordinate for async processing loop.
     */
    private currentY: number;

    /**
     * Stores destination image between async loop iterations.
     */
    private destinationImage: ImageAdapter<ConcreteAdapter>;

    /**
     * Stores Promises' resolve() callback which will be called after async loop will be finished.
     */
    private resolve: Function;

    /**
     * @constructor
     * @param image Image being distorted.
     * @param pixelMapper Pixel mapper used for distortion.
     * @param resampler Color resampler.
     * @param viewport Viewport setting. Can be specific {@link Viewport} instance, 'bestFit' string constant or boolean
     * true for calculation of best-fit viewport. When set to null, original image viewport (cloned) will be used.
     * @param [outputScaling=1] Output scaling factor for super-sampling.
     * @param [asyncTimeout=50] Time in milliseconds before further pixel processing will be delayed.
     * @param [repage=false] Repaging parameter: boolean true will reset distorted image's viewport offset to (0, 0). Tuple
     * of [x, y] will set distorted image's viewport offset to (x, y). Boolean false means that distortion offset will be kept.
     */
    constructor(
        image: ImageAdapter<ConcreteAdapter>,
        pixelMapper: ReversePixelMapper,
        resampler: ColorResampler,
        viewport: Viewport|'bestFit'|true|null,
        outputScaling: number = 1,
        asyncTimeout: number = 50,
        repage: boolean|[number, number] = false
    ) {
        if (asyncTimeout < 1) {
            throw new InvalidArgument(`Param 'asyncTimeout' must be > 0. ${asyncTimeout} was passed.`);
        }
        this.image = image;
        this.pixelMapper = pixelMapper;
        this.resampler = resampler;
        this.bestFit = viewport === 'bestFit' || viewport === true ||
            isReversePixelMapperWithBestFit(this.pixelMapper) && !!this.pixelMapper.forceBestFit;
        this.outputScaling = outputScaling;
        this.asyncTimeout = asyncTimeout;
        this.repage = repage;
        this.viewport = viewport instanceof Viewport ? viewport : null;
    }

    /**
     * Processes image distortion.
     *
     * @returns Promise resolving {@link DistortionResult} object.
     */
    process(): Promise<DistortionResult<ConcreteAdapter>> {
        const startTimestamp = Date.now();
        return this.init()
            .then(image => this.scaleSupersampled(image))
            .then(image => {
                if (this.repage === true) {
                    image.getViewport().reset();
                } else if (Array.isArray(this.repage)) {
                    image.getViewport().offset(...this.repage);
                }

                return image;
            })
            .then(image => {
                const endTimestamp = Date.now();
                const duration = endTimestamp - startTimestamp;
                const distortion = this.pixelMapper;
                let weightLookupTable;

                if (this.resampler instanceof EllipticalWeightedAverage) {
                    weightLookupTable = this.resampler.weightLookupTable;
                }

                return {
                    image,
                    distortion,
                    startTimestamp,
                    endTimestamp,
                    duration,
                    weightLookupTable
                }
            });
    }

    /**
     * Initializes and actually starts distortion process.
     *
     * @returns Promise resolving distorted image.
     */
    private init(): Promise<ConcreteAdapter> {
        this.resampler.setScaling(1 / this.outputScaling);
        const vp = this.getDestinationViewport();
        this.startX = Math.floor(vp.x1);
        this.endX = Math.floor(vp.x2);
        this.endY = Math.floor(vp.y2);
        this.currentX = this.startX;
        this.currentY = Math.floor(vp.y1);

        return this.image.getBlank(vp)
            .then(destinationImage => {
                this.destinationImage = destinationImage;

                return new Promise(resolve => {
                    this.resolve = resolve;
                    this.processDistortion();
                });
            });
    }

    /**
     * Processes image distortion asynchronously.
     */
    private processDistortion(): void {
        const resampler = this.resampler;
        const destinationImage = this.destinationImage;
        const asyncTimeout = this.asyncTimeout;
        const startX = this.startX;
        const endX = this.endX;
        const endY = this.endY;
        let x = this.currentX;
        let y = this.currentY;
        const startTime = Date.now();

        while (y <= endY) {
            while (x <= endX) {
                destinationImage.setPixelColor(
                    x,
                    y,
                    resampler.getResampledColor(x, y)
                );
                x++;

                if (Date.now() - startTime >= asyncTimeout) {
                    this.currentX = x;
                    this.currentY = y;
                    setTimeout(this.processDistortion.bind(this), 0);
                    return;
                }
            }

            y++;
            x = startX;
        }

        destinationImage.commit()
            .then(() => this.resolve(destinationImage));
    }

    /**
     * Scales down oversized image to achieve super-sampling effect.
     *
     * @param image Oversized distorted image.
     * @returns Promise resolving downscaled image.
     */
    private scaleSupersampled(image: ConcreteAdapter): Promise<ConcreteAdapter> {
        if (this.outputScaling !== 1) {
            return image.scale(1 / this.outputScaling);
        }

        return Promise.resolve(image);
    }

    /**
     * Returns viewport for destination image based on provided settings.
     *
     * @returns Viewport for destination image.
     */
    private getDestinationViewport(): Viewport {
        let viewport: Viewport;

        if (this.viewport) {
            viewport = this.viewport.clone();
        } else if (this.bestFit && isReversePixelMapperWithBestFit(this.pixelMapper)) {
            viewport = this.pixelMapper.getBestFitViewport(this.image.getViewport());
        } else {
            viewport = this.image.getViewport().clone();
        }

        viewport.scale(this.outputScaling);

        return viewport;
    }
}