import {ReversePixelMapperWithBestFit} from "./ReversePixelMapperWithBestFit";
import {isReversePixelMapper} from "./isReversePixelMapper";

/**
 * Checks if object implements ReversePixelMapperWithBestFit interface.
 *
 * @param obj
 *
 * @category Reverse Pixel Mapper
 */
export function isReversePixelMapperWithBestFit(obj: any): obj is ReversePixelMapperWithBestFit {
    return (isReversePixelMapper(obj) as boolean) &&
        'getBestFitViewport' in obj && typeof obj.getBestFitViewport === 'function';
}