import {PerspectiveMatrix} from "./PerspectiveMatrix";
import {Perspective} from "./Perspective";

/**
 * Creates perspective distortion using perspective matrix.
 *
 * @param matrix Perspective matrix.
 * @returns Perspective instance.
 * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l00853 Generating inverted perspective distortion matrix from forward perspective matrix at ImageMagick docs}
 * @category Reverse Pixel Mapper Factory
 */
export function PerspectiveProjectionFactory(matrix: PerspectiveMatrix): Perspective {
    return Perspective.fromForwardMatrix(matrix);
}