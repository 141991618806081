import {Affine} from "./Affine";

/**
 * Creates affine distortion using forward affine matrix.
 *
 * @param matrix Affine projection coefficients: [sx, ry, rx, sy, tx, ty].
 * @returns Affine instance.
 * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l00609 Generating inverted affine distortion matrix from forward affine matrix at ImageMagick source}
 * @category Reverse Pixel Mapper Factory
 */
export function AffineProjectionFactory(
    [sx, ry, rx, sy, tx, ty]: [number, number, number, number, number, number]
): Affine {
    return Affine.fromForwardMatrix([sx, rx, tx, ry, sy, ty]);
}