import {LensException} from './LensException';

/**
 * Invalid Argument Exception.
 *
 * @category Exception
 */
export class InvalidArgument extends LensException {
    /**
     * @param message Error message.
     */
    constructor(message: string) {
        super(message);
        this.name = 'InvalidArgument';
    }
}