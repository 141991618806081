/**
 * Registry of reverse pixel mappers (distortions) factories.
 * @internal
 * @module
 */
import {InvalidArgument} from "../exception/InvalidArgument";
import {NotFound} from "../exception/NotFound";
import {NotImplemented} from "../exception/NotImplemented";
import {ReservedName} from "../exception/ReservedName";
import {Viewport} from "../image/index";
import {AffineFactory, AffineProjectionFactory} from "./affine";
import {ArcFactory} from "./arc";
import {Distortion} from "./Distortion";
import {DistortionFactory} from "./DistortionFactory";
import {PerspectiveFactory, PerspectiveProjectionFactory} from "./perspective";
import {ReversePixelMapper} from "./ReversePixelMapper";

/**
 * Distortion factories registry.
 *
 * @internal
 */
const registry: Map<string, DistortionFactory> = new Map();

// Register factories for inbuilt distortions
registry.set(Distortion.ARC, (ArcFactory as DistortionFactory));
registry.set(Distortion.AFFINE, AffineFactory);
registry.set(Distortion.AFFINE_PROJECTION, AffineProjectionFactory);
registry.set(Distortion.PERSPECTIVE, PerspectiveFactory);
registry.set(Distortion.PERSPECTIVE_PROJECTION, (PerspectiveProjectionFactory as DistortionFactory));

// Throw NotImplemented exception when try to instantiate not implemented distortion.
const notImplemented = (name: string): (() => never) => () => {
    throw new NotImplemented(`Distortion '${name}' is not implemented yet.`);
};
const implementedDistortions = Array.from(registry.keys());
Object.values<string>(Distortion).filter(key => !implementedDistortions.includes(key))
    .forEach(notImplementedDistortion => {
        registry.set(notImplementedDistortion, notImplemented(notImplementedDistortion));
    });

/**
 * Checks if given name is reserved for inbuilt distortions.
 *
 * @param name
 *
 * @category Reverse Pixel Mapper
 */
export function isReservedDistortionName(name: string): boolean {
    return Object.values<string>(Distortion).includes(name);
}

/**
 * Checks if distortion under given name is registered.
 *
 * @param name Distortion name.
 *
 * @category Reverse Pixel Mapper
 */
export function isRegisteredDistortionName(name: string): boolean {
    return registry.has(name);
}

/**
 * Registers reverse pixel mapper (distortion) factory under given name.
 *
 * @param name Distortion name.
 * @param factory Distortion factory.
 * @param [replace=false] Replace existing registered factory if true.
 * @throws {@link InvalidArgument} When trying to register distortion under name which is already registered and not
 * forced to be replaced.
 * @throws {@link ReservedName} When trying to register distortion under reserved name.
 *
 * @category Reverse Pixel Mapper
 */
export function registerDistortionFactory(name: string, factory: DistortionFactory, replace = false): void {
    if (isReservedDistortionName(name)) {
        throw new ReservedName(`Distortion name '${name}' is reserved.`);
    }

    if (isRegisteredDistortionName(name) && !replace) {
        throw new InvalidArgument(
            `Distortion name '${name}' is already registered. Choose another name or set 'replace' param to true.`
        );
    }

    registry.set(name, factory);
}

/**
 * Unregisters distortion under given name.
 *
 * @param name Distortion name.
 * @throws {@link ReservedName} When trying to unregister distortion under reserved name.
 *
 * @category Reverse Pixel Mapper
 */
export function unregisterDistortionFactory(name: string): void {
    if (isReservedDistortionName(name)) {
        throw new ReservedName(`Can't unregister distortion '${name}', because it's reserved.`);
    }

    if (isRegisteredDistortionName(name)) {
        registry.delete(name);
    }
}

/**
 * Returns distortion factory from registry by name.
 *
 * @param name Distortion name.
 * @returns Distortion factory.
 * @throws {@link NotFound} When distortion under given name can't be found.
 *
 * @category Reverse Pixel Mapper
 */
export function getDistortionFactory(name: string): DistortionFactory {
    if (isRegisteredDistortionName(name)) {
        const factory = registry.get(name);
        if (factory) {
            return factory;
        }
    }

    throw new NotFound(`Distortion '${name}' was not found.`);
}

/**
 * Creates distortion using its name, arguments and optionally a source image viewport.
 *
 * @param name Distortion name.
 * @param args Distortion arguments.
 * @param [viewport] Source image viewport.
 * @throws {@link NotFound}
 *
 * @category Reverse Pixel Mapper
 */
export function resolveDistortion(name: string, args: number[], viewport?: Viewport): ReversePixelMapper {
    return getDistortionFactory(name).call(null, args, viewport);
}