import {Viewport} from "../../image/Viewport";
import {AffineMatrix} from "./AffineMatrix";
import {ReversePixelMapperWithEwaSupport} from "../ReversePixelMapperWithEwaSupport";
import {ReversePixelMapperWithBestFit} from "../ReversePixelMapperWithBestFit";
import {ForwardPixelMapper} from "../ForwardPixelMapper";
import {applyAffineMatrix} from "./applyAffineMatrix";
import {invertAffineMatrix} from "./invertAffineMatrix";

/**
 * Affine distortion.
 *
 * @see {@link https://www.imagemagick.org/Usage/distorts/#affine Affine distortion details at ImageMagick docs}
 * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l02443 Affine distortion at ImageMagick source}
 * @category Reverse Pixel Mapper
 */
export class Affine implements ReversePixelMapperWithEwaSupport, ReversePixelMapperWithBestFit, ForwardPixelMapper {
    /**
     * Reverse matrix.
     */
    readonly matrix: AffineMatrix;

    /**
     * Forward matrix.
     */
    readonly forwardMatrix: AffineMatrix;

    /**
     * @inheritDoc
     */
    readonly hasConstantPartialDerivatives: true;

    /**
     * @param matrix Affine matrix.
     */
    constructor(matrix: AffineMatrix) {
        this.matrix = matrix;
        this.forwardMatrix = invertAffineMatrix(matrix);
        this.hasConstantPartialDerivatives = true;
    }

    /**
     * Creates affine distortion using affine matrix.
     *
     * @param matrix Affine projection coefficients: [sx, rx, tx, ry, sy, ty].
     * @returns Affine instance.
     * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l00609 Generating inverted affine distortion matrix from forward affine matrix at ImageMagick source}
     */
    static fromForwardMatrix(matrix: AffineMatrix): Affine {
        return new Affine(
            invertAffineMatrix(matrix)
        );
    }

    /**
     * Maps source coords into destination coords.
     *
     * @param x
     * @param y
     * @returns Reverse mapped coordinates pair.
     */
    reverseMap(x: number, y: number): [number, number] {
        return applyAffineMatrix(x, y, this.matrix);
    }

    /**
     * @inheritDoc
     */
    getValidity(x: number, y: number): 1 {
        return 1;
    }

    /**
     * @inheritDoc
     */
    getPartialDerivatives(
        x: number,
        y: number
    ): [number, number, number, number] {
        return [
            this.matrix[0],
            this.matrix[1],
            this.matrix[3],
            this.matrix[4]
        ];
    }

    /**
     * @inheritDoc
     */
    forwardMap(u: number, v: number): [number, number] {
        return applyAffineMatrix(u, v, this.forwardMatrix);
    }

    /**
     * @inheritDoc
     */
    getBestFitViewport(viewport: Viewport): Viewport {
        const u1 = viewport.x1,
            v1 = viewport.y1,
            u2 = viewport.x2 + 1,
            v2 = viewport.y2 + 1,
            [x, y] = this.forwardMap(u1, v1),
            bestFit = new Viewport(x, y, x, y);

        [[u2, v1], [u2, v2], [u1, v2]].forEach((apex: [number, number]) => bestFit.expand(...this.forwardMap(...apex)));

        bestFit.fixBounds();

        return bestFit;
    }
}