import {blendColors} from "../util";
import {ImageAdapter} from "../image/ImageAdapter";
import {Color} from "../types/Color";
import {ReversePixelMapper} from "../distortion/ReversePixelMapper";
import {ColorResampler} from "./ColorResampler";

/**
 * Simple implementation without resampling, using interpolation only.
 * It uses pixel color interpolation and works much faster than {@link EllipticalWeightedAverage} but produces aliasing
 * effects.
 * It is good for quick creation of distortion previews and also used for {@link ReversePixelMapper} implementations
 * that doesn't have partial derivatives.
 * It works most quickly when {@link ImageAdapter.interpolationMethod} is set to
 * {@link InterpolationMethod.INTEGER}
 *
 * @category Color Resampler
 */
export class Point<ConcreteAdapter extends ImageAdapter<ConcreteAdapter> = ImageAdapter<any>>
    implements ColorResampler {
    /**
     * Image being resampled.
     */
    private readonly image: ImageAdapter<ConcreteAdapter>;

    /**
     * Distortion mapper.
     */
    private readonly pixelMapper: ReversePixelMapper;

    /**
     * Matte color for invalid mappings.
     */
    private readonly matteColor: Color;

    /**
     * Output image scaling factor.
     */
    private scaling: number;

    /**
     * @param image Image being resampled.
     * @param pixelMapper Distortion pixel mapper.
     * @param [matteColor=[0, 0, 0, 0]] Matte color.
     */
    constructor(
        image: ImageAdapter<ConcreteAdapter>,
        pixelMapper: ReversePixelMapper,
        matteColor: Color = [0, 0, 0, 0]
    ) {
        this.image = image;
        this.pixelMapper = pixelMapper;
        this.matteColor = matteColor;
        this.scaling = 1;
    }

    /**
     * @inheritDoc
     */
    getScaling(): number {
        return this.scaling;
    }

    /**
     * @inheritDoc
     */
    setScaling(scaling: number): this {
        this.scaling = scaling;
        return this;
    }

    /**
     * @inheritDoc
     */
    getResampledColor(x: number, y: number): Color {
        x = (x + 0.5) * this.scaling;
        y = (y + 0.5) * this.scaling;

        const validity = this.pixelMapper.getValidity(x, y, this.scaling);

        if (validity > 0) {
            let [u, v] = this.pixelMapper.reverseMap(x, y);
            let color = this.image.getInterpolatedPixelColor(u - 0.5, v - 0.5);

            if (validity < 1) {
                return blendColors(color, this.matteColor, validity);
            }

            return color;
        }

        return this.matteColor;
    }
}