import {Perspective} from "./Perspective";
import {InvalidArgumentsLength} from "../../exception";
import {LeastSquaresSolver} from "../../math";
import {PerspectiveMatrix} from "./PerspectiveMatrix";

/**
 * Creates perspective distortion using control points array.
 *
 * @param controlPoints Mappings of control points [u0, v0, x0, y0, ... , un, vn, xn, yn] where
 * (u*, v*) are source (x, y) point and (x*, y*) are destination (x, y) point.
 * @returns Perspective instance.
 * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l00745 Generating perspective distortion matrix from control points at ImageMagick source}
 * @category Reverse Pixel Mapper Factory
 */
export function PerspectiveFactory(controlPoints: number[]): Perspective {
    if (controlPoints.length < 16 || controlPoints.length % 4 !== 0) {
        throw new InvalidArgumentsLength(
            `Number of arguments must be multiple of 4 and at least 16 arguments (4 control points) expected. ` +
            `${controlPoints.length} arguments given.`
        );
    }

    const leastSquares = new LeastSquaresSolver(8, 1);

    for (let i = 0; i < controlPoints.length; i += 4) {
        let [u, v, x, y] = controlPoints.slice(i, i + 4);

        leastSquares.addTerms([
            x, y, 1,
            0, 0, 0,
            -x * u, -y * u
        ], [u])
            .addTerms([
                0, 0, 0,
                x, y, 1,
                -x * v, -y * v
            ], [v]);
    }

    const matrix = leastSquares.getVectors()[0] as PerspectiveMatrix;

    /*
     * Calculate denominator! The ground-sky determination.
     * What is sign of the 'ground' in r() denominator affine function?
     * Just use any valid image coordinate (first control point) in
     * destination for determination of what part of view is 'ground'.
     */
    const denominator = matrix[6] * controlPoints[2] + matrix[7] * controlPoints[3] + 1 < 0 ? -1 : 1;

    return new Perspective(matrix, denominator);
}