import {PerspectiveMatrix} from "./PerspectiveMatrix";
import {InvalidArgument} from "../../exception";
import {perceptibleReciprocal} from "../../math/perceptibleReciprocal";

/**
 * Creates inverted perspective matrix from perspective matrix.
 *
 * @param matrix Perspective matrix.
 * @returns Inverted perspective matrix.
 * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l00109 Inverting perspective matrix at ImageMagick source}
 */
export function invertPerspectiveMatrix(matrix: PerspectiveMatrix): PerspectiveMatrix {
    const determinant = perceptibleReciprocal(matrix[0] * matrix[4] - matrix[3] * matrix[1]);

    if (!determinant) {
        throw new InvalidArgument("Given matrix can't be inverted");
    }

    return [
        determinant * (matrix[4] - matrix[7] * matrix[5]),
        determinant * (matrix[7] * matrix[2] - matrix[1]),
        determinant * (matrix[1] * matrix[5] - matrix[4] * matrix[2]),
        determinant * (matrix[6] * matrix[5] - matrix[3]),
        determinant * (matrix[0] - matrix[6] * matrix[2]),
        determinant * (matrix[3] * matrix[2] - matrix[0] * matrix[5]),
        determinant * (matrix[3] * matrix[7] - matrix[6] * matrix[4]),
        determinant * (matrix[6] * matrix[1] - matrix[0] * matrix[7])
    ];
}