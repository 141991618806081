/**
 * Base Lens exception.
 *
 * @category Exception
 */
export class LensException extends Error {

    /**
     * @param message Error message.
     */
    constructor(message: string) {
        super(message);
        this.name = 'LensException';

        if (typeof (Error as any).captureStackTrace === 'function') {
            (Error as any).captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }
}