import {PerspectiveMatrix} from "./PerspectiveMatrix";

/**
 * Project coordinates using projection matrix.
 *
 * @param x X-coordinate.
 * @param y Y-coordinate.
 * @param matrix Perspective matrix.
 * @returns Mapped coords pair.
 */
export function applyPerspectiveMatrix(x: number, y: number, matrix: PerspectiveMatrix): [number, number] {
    let p = matrix[0] * x + matrix[1] * y + matrix[2],
        q = matrix[3] * x + matrix[4] * y + matrix[5],
        r = matrix[6] * x + matrix[7] * y + 1;

    return [p / r, q / r];
}