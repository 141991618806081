/**
 * Weighting function factories registry.
 * @module
 * @internal
 */
import {InvalidArgument} from "../exception/InvalidArgument";
import {NotFound} from "../exception/NotFound";
import {ReservedName} from "../exception/ReservedName";
import {BoxFactory} from "./BoxFactory";
import {CubicBCFactory} from "./CubicBCFactory";
import {WeightingFunction} from "./WeightingFunction";
import {WeightingFunctionName} from "./WeightingFunctionName";

/**
 * Weighting function factories registry.
 */
const registry: Map<string, (...args: any[]) => WeightingFunction> = new Map();

// Register inbuilt weighting function factories
registry.set(WeightingFunctionName.BOX, BoxFactory);
registry.set(WeightingFunctionName.CUBIC_BC, CubicBCFactory);

/**
 * Checks if given name is reserved for inbuilt weighting function factories.
 *
 * @param name Weighting function factory name.
 *
 * @category Filter Weighting Function
 */
export function isReservedWeightingFunctionName(name: string): boolean {
    return Object.values<string>(WeightingFunctionName).includes(name);
}

/**
 * Checks if weighting function factory with given name is registered.
 *
 * @param name Weighting function factory name.
 *
 * @category Filter Weighting Function
 */
export function isRegisteredWeightingFunctionName(name: string): boolean {
    return registry.has(name);
}

/**
 * Registers weighting function factory under given name.
 *
 * @param name Weighting function factory name.
 * @param factory Weighting function factory.
 * @param [replace=false] Replace existing factory if any.
 * @throws {@link InvalidArgument} When trying to register factory under name which is already registered and not forced
 * to be replaced.
 * @throws {@link ReservedName} When trying to register factory under reserved name.
 *
 * @category Filter Weighting Function
 */
export function registerWeightingFunctionFactory(name: string, factory: (...args: any[]) => WeightingFunction, replace: boolean = false): void {
    if (isReservedWeightingFunctionName(name)) {
        throw new ReservedName(`Weighting function factory name '${name}' is reserved.`);
    }

    if (isRegisteredWeightingFunctionName(name) && !replace) {
        throw new InvalidArgument(
            `Weighting function factory name '${name}' is already registered. Choose another name or set 'replace' param to true.`
        );
    }

    registry.set(name, factory);
}

/**
 * Unregisters weighting function factory under given name.
 *
 * @param name Weighting function factory name.
 * @throws {@link ReservedName} When trying to unregister factory under reserved name.
 *
 * @category Filter Weighting Function
 */
export function unregisterWeightingFunctionFactory(name: string): void {
    if (isReservedWeightingFunctionName(name)) {
        throw new ReservedName(`Can't unregister weighting function factory '${name}', because it's reserved.`);
    }

    if (isRegisteredWeightingFunctionName(name)) {
        registry.delete(name);
    }
}

/**
 * Returns weighting function factory, registered under given name.
 *
 * @param name Weighting function factory name.
 * @throws {@link NotFound} When factory under given name can't be found.
 *
 * @category Filter Weighting Function
 */
export function getWeightingFunctionFactory(name: string): (...args: any[]) => WeightingFunction {
    if (isRegisteredWeightingFunctionName(name)) {
        const factory = registry.get(name);
        if (factory !== undefined) {
            return factory;
        }
    }

    throw new NotFound(`Weighting function factory '${name}' not found in registry.`);
}

/**
 * Returns weighting function by its name and factory arguments.
 *
 * @param factoryName Weighting function name.
 * @param factoryArgs Weighting function factory arguments.
 * @throws {@link NotFound}
 *
 * @category Filter Weighting Function
 */
export function createWeightingFunction(factoryName: string, factoryArgs: any[]): WeightingFunction {
    return getWeightingFunctionFactory(factoryName).apply(null, factoryArgs);
}