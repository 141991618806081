import {DistortionOptions} from "./types/DistortionOptions";
import {FilterName} from "./filter/FilterName";
import {InterpolationMethod} from "./image/InterpolationMethod";
import {VirtualPixelMethod} from "./image/VirtualPixelMethod";

export const defaultOptions: DistortionOptions = {
    asyncTimeout: 50,

    viewport: null,

    resampler: 'ewa',

    filter: FilterName.ROBIDOUX,

    filterBlur: 1,

    filterWindowSupport: null,

    repage: false,

    matteColor: [0, 0, 0, 0],

    outputScaling: 1,

    imageViewportOffset: null,

    imageBackgroundColor: null,

    imageVirtualPixelMethod: VirtualPixelMethod.TRANSPARENT,

    imageInterpolationMethod: InterpolationMethod.AVERAGE,
}