import {InvalidArgument} from "./InvalidArgument";

/**
 * Exception for not implemented functionality calls.
 *
 * @category Exception
 */
export class NotImplemented extends InvalidArgument {
    /**
     * @param message Error message.
     */
    constructor(message: string) {
        super(message);
        this.name = 'NotImplemented';
    }
}