import {ImageAdapter} from "./ImageAdapter";

const props = {
    width: 'number',
    height: 'number'
};

const methods = [
    'getViewport',
    'setViewport',
    'getBackgroundColor',
    'setBackgroundColor',
    'getVirtualPixelMethod',
    'setVirtualPixelMethod',
    'getInterpolationMethod',
    'setInterpolationMethod',
    'getQuantumRange',
    'getPixelColor',
    'setPixelColor',
    'getAverageColor',
    'getBlank',
    'getInterpolatedPixelColor',
    'scale',
    'getResource'
];

/**
 * Type guard for {@link ImageAdapter}.
 *
 * @param obj
 *
 * @category Image Adapter
 */
export function isImageAdapter(obj: any): obj is ImageAdapter<any> {
    return typeof obj === 'object' && obj !== null &&
        Object.keys(props).reduce((prevCheck: boolean, key: keyof {width: string, height: string}): boolean => {
            return prevCheck && key in obj && typeof obj[key] === props[key];
        }, true) &&
        methods.reduce((prevCheck: boolean, methodName: string): boolean => {
            return prevCheck && methodName in obj && typeof obj[methodName] === 'function';
        }, true);
}