import {LensException} from './LensException';

/**
 * Invalid method call exception.
 *
 * @category Exception
 */
export class InvalidMethodCall extends LensException {
    /**
     * @param message Error message.
     */
    constructor(message: string) {
        super(message);
        this.name = 'InvalidMethodCall';
    }
}