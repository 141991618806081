import {WeightingFunction} from "./WeightingFunction";

/**
 * Makes box filter function.
 * A Box filter is an equal weighting function (all weights equal).
 * DO NOT LIMIT results by support or resize point sampling will work
 * as it requests points beyond its normal 0.0 support size.
 *
 * @returns Box filter function.
 * @see {@link https://imagemagick.org/api/MagickCore/resize_8c_source.html#l00181 Ox filter function at ImageMagick source}
 *
 * @category Filter Weighting Function
 */
export function BoxFactory(): WeightingFunction {
    return function box(): 1 {
        return 1;
    }
}