import {ViewportLiteral} from "./ViewportLiteral";

/**
 * Type guard for viewport literal.
 *
 * @param obj
 *
 * @category Image Adapter
 */
export function isViewportLiteral(obj: any): obj is ViewportLiteral {
    return typeof obj === 'object' && obj !== null && (
        'x1' in obj && typeof obj.x1 === 'number' &&
        'y1' in obj && typeof obj.y1 === 'number' &&
        'x2' in obj && typeof obj.x2 === 'number' &&
        'y2' in obj && typeof obj.y2 === 'number' ||
        'width' in obj && typeof obj.width === 'number' &&
        'height' in obj && typeof obj.height === 'number' &&
        (!('x' in obj) || typeof obj.x === 'number') &&
        (!('y' in obj) || typeof obj.y === 'number')
    );
}

/**
 * Virtual viewport class.
 * Represents image's virtual position at it's coordinate space.
 *
 * @see {@link https://www.imagemagick.org/Usage/basics/#page Virtual canvas offset} at ImageMagick docs.
 *
 * @category Image Adapter
 */
export class Viewport {
    /**
     * Left edge coordinate of viewport.
     */
    x1: number;

    /**
     * Top edge coordinate of viewport.
     */
    y1: number;

    /**
     * Right edge coordinate of viewport.
     */
    x2: number;

    /**
     * Bottom edge coordinate of viewport.
     */
    y2: number;

    /**
     * @param x1 Left edge coordinate of viewport.
     * @param y1 Top edge coordinate  of viewport.
     * @param x2 Right edge coordinate of viewport.
     * @param y2 Bottom edge coordinate of viewport.
     */
    constructor(
        x1: number,
        y1: number,
        x2: number,
        y2: number
    ) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
    }

    /**
     * Creates Viewport instance from viewport literal.
     * @param obj
     */
    static fromLiteral(obj: ViewportLiteral): Viewport {
        let x1, y1, x2, y2;

        if ('width' in obj && 'height' in obj) {
            x1 = obj.x || 0;
            y1 = obj.y || 0;
            x2 = x1 + obj.width - 1;
            y2 = y1 + obj.height - 1;
        } else {
            x1 = obj.x1;
            y1 = obj.y1;
            x2 = obj.x2;
            y2 = obj.y2;
        }

        return new Viewport(x1, y1, x2, y2);
    }

    /**
     * Returns virtual viewport width -- same as image actual width.
     */
    getWidth(): number {
        return this.x2 - this.x1 + 1;
    }

    /**
     * Returns virtual viewport height -- same as image actual height.
     */
    getHeight(): number {
        return this.y2 - this.y1 + 1;
    }

    /**
     * Returns viewport area.
     */
    getArea(): number {
        return this.getWidth() * this.getHeight();
    }

    /**
     * Checks if viewport contains given coords.
     *
     * @param x X-coordinate.
     * @param y Y-coordinate.
     */
    contains(x: number, y: number): boolean {
        return x >= this.x1 && x <= this.x2 && y >= this.y1 && y <= this.y2;
    }

    /**
     * Expands viewport to contain given coords.
     *
     * @param x X-coordinate.
     * @param y Y-coordinate.
     */
    expand(x: number, y: number): this {
        this.x1 = Math.min(this.x1, x);
        this.x2 = Math.max(this.x2, x);
        this.y1 = Math.min(this.y1, y);
        this.y2 = Math.max(this.y2, y);
        return this;
    }

    /**
     * Clones viewport into new instance.
     *
     * @returns New Viewport instance with same dimensions.
     */
    clone(): Viewport {
        return new Viewport(this.x1, this.y1, this.x2, this.y2);
    }

    /**
     * Fix bounds after best fit viewport calculation.
     */
    fixBounds(): this {
        this.x1 = Math.floor(this.x1 - 0.5);
        this.y1 = Math.floor(this.y1 - 0.5);
        this.x2 = Math.ceil(this.x2 - 0.5);
        this.y2 = Math.ceil(this.y2 - 0.5);
        return this;
    }

    /**
     * Scales viewport bounds.
     *
     * @param scale Scale value.
     */
    scale(scale: number): this {
        this.x1 = this.x1 * scale;
        this.y1 = this.y1 * scale;
        this.x2 = this.x2 * scale;
        this.y2 = this.y2 * scale;
        return this;
    }

    /**
     * Resets viewport offset.
     */
    reset(): this {
        let width = this.getWidth(),
            height = this.getHeight();
        this.x1 = 0;
        this.y1 = 0;
        this.x2 = this.x1 + width - 1;
        this.y2 = this.y1 + height - 1;
        return this;
    }

    /**
     * Sets viewport offset.
     *
     * @param x X-offset.
     * @param y Y-offset.
     */
    offset(x: number, y: number): this {
        this.x1 += x;
        this.y1 += y;
        this.x2 += x;
        this.y2 += y;
        return this;
    }
}