import {resolveDistortion} from "./distortion/distortionsRegistry";
import {createResampleFilterByPresetName, createResampleFilterUsingPreset} from "./filter/presetsRegistry";
import {ImageAdapter} from "./image/ImageAdapter";
import {isViewportLiteral, Viewport} from "./image/Viewport";
import {Distortion} from "./distortion/Distortion";
import {DistortionProcessor} from "./distortion/DistortionProcessor";
import {ReversePixelMapper} from "./distortion/ReversePixelMapper";
import {ViewportLiteral} from "./image/ViewportLiteral";
import {DistortionOptions} from "./types/DistortionOptions";
import {DistortionResult} from "./types/DistortionResult";
import {resolveImageAdapterForResource as resolveImageAdapter} from "./image/adaptersBindings";
import {defaultOptions} from "./defaultOptions";
import {ColorResampler} from "./resampler";
import {FilterName} from "./filter/FilterName";
import {FilterPreset} from "./filter/FilterPreset";
import {ResampleFilter} from "./filter/ResampleFilter";
import {EllipticalWeightedAverage} from "./resampler/EllipticalWeightedAverage";
import {Point} from "./resampler/Point";
import {InvalidArgument} from "./exception/InvalidArgument";
import {isReversePixelMapper} from "./distortion/isReversePixelMapper";
import {isReversePixelMapperWithEwaSupport} from "./distortion/isReversePixelMapperWithEwaSupport";
import {isFilterPreset} from "./filter/isFilterPreset";
import {isResampleFilter} from "./filter/isResampleFilter";
import {isImageAdapter} from "./image/isImageAdapter";

/**
 * Merges distortion options.
 *
 * @param options Distortion options (will override default options).
 * @param defaultOptions Default distortion options.
 * @returns Merged distortion options.
 * @internal
 */
function mergeOptions(options: Partial<DistortionOptions>, defaultOptions: DistortionOptions): DistortionOptions {
    return Object.assign({}, defaultOptions, options);
}

/**
 * Lens class. Handles image distortion flow.
 */
export class Lens {
    /**
     * Default instance options.
     */
    defaultOptions: DistortionOptions;

    /**
     * @param [options={}] Default options to use.
     */
    constructor(options: Partial<DistortionOptions> = {}) {
        this.defaultOptions = mergeOptions(options, defaultOptions);
    }

    /**
     * Distorts image using distortion name and arguments.
     *
     * @param image Image to distort.
     * @param distortion Distortion name.
     * @param args Distortion arguments.
     * @param [options] Distortion options.
     */
    distort<ConcreteAdapter extends ImageAdapter<ConcreteAdapter>>(
        image: object | ImageAdapter<ConcreteAdapter> | Promise<ImageAdapter<ConcreteAdapter>>,
        distortion: Distortion | string,
        args: number[],
        options?: Partial<DistortionOptions>
    ): Promise<DistortionResult<ConcreteAdapter>>;

    /**
     * Distorts image using distortion object (reverse pixel mapper).
     *
     * @param image Image to distort.
     * @param distortion Prepared distortion object (reverse pixel mapper).
     * @param [options] Distortion options.
     */
    distort<ConcreteAdapter extends ImageAdapter<ConcreteAdapter>>(
        image: object | ImageAdapter<ConcreteAdapter> | Promise<ImageAdapter<ConcreteAdapter>>,
        distortion: ReversePixelMapper,
        options?: Partial<DistortionOptions>
    ): Promise<DistortionResult<ConcreteAdapter>>;

    distort<ConcreteAdapter extends ImageAdapter<ConcreteAdapter>>(
        image: object | ImageAdapter<ConcreteAdapter> | Promise<ImageAdapter<ConcreteAdapter>>,
        distortion: Distortion | string | ReversePixelMapper,
        args?: number[] | Partial<DistortionOptions>,
        options?: Partial<DistortionOptions>
    ): Promise<DistortionResult<ConcreteAdapter>> {
        return this.resolveImageAdapter(image)
            .then(imageAdapter => {
                if (Array.isArray(args)) {
                    options = options || {};
                } else {
                    options = options || args || {};
                    args = [];
                }

                let runtimeOptions: DistortionOptions = this.mergeOptions(options),
                    pixelMapper: ReversePixelMapper;

                if (typeof distortion === 'string') {
                    pixelMapper = this.resolvePixelMapper(distortion, args, imageAdapter);
                } else if (isReversePixelMapper(distortion)) {
                    pixelMapper = distortion;
                } else {
                    throw new InvalidArgument(
                        `Param 'distortion' must be either string with registered distortion name or object ` +
                        `implementing ReversePixelMapper interface.`
                    );
                }

                this.setupImageAdapterOptions(imageAdapter, runtimeOptions);

                return new DistortionProcessor<ConcreteAdapter>(
                    imageAdapter,
                    pixelMapper,
                    this.resolveResampler(imageAdapter, pixelMapper, runtimeOptions),
                    this.resolveViewportOption(runtimeOptions.viewport),
                    runtimeOptions.outputScaling,
                    runtimeOptions.asyncTimeout
                ).process();
            });

    }

    /**
     * Resolves image adapter as promise.
     *
     * @param image Image in one of supported forms.
     */
    protected resolveImageAdapter<ConcreteAdapter extends ImageAdapter<ConcreteAdapter>>(
        image: object | ImageAdapter<ConcreteAdapter> | Promise<ImageAdapter<ConcreteAdapter>>
    ): Promise<ImageAdapter<ConcreteAdapter>> {
        if ('then' in image) {
            return image;
        }

        if (isImageAdapter(image)) {
            return Promise.resolve(image);
        }

        return Promise.resolve(
            resolveImageAdapter(image)
        );
    }

    /**
     * Resolves pixel mapper instance.
     *
     * @param distortion Registered distortion name.
     * @param args Distortion arguments.
     * @param image Image being distorted.
     */
    protected resolvePixelMapper<ConcreteAdapter extends ImageAdapter<ConcreteAdapter>>(
        distortion: string | Distortion,
        args: number[],
        image: ImageAdapter<ConcreteAdapter>
    ): ReversePixelMapper {
        return resolveDistortion(distortion, args, image.getViewport().clone());
    }

    /**
     * Merges provided options with instance default options.
     *
     * @param options Distortion options.
     * @returns Merged distortion options.
     */
    protected mergeOptions(options: Partial<DistortionOptions> = {}): DistortionOptions {
        return mergeOptions(options, this.defaultOptions);
    }

    /**
     * Resolves resampler object.
     *
     * @param image Image being distorted.
     * @param distortion Distortion object (reverse pixel mapper).
     * @param options Distortion options.
     */
    protected resolveResampler<ConcreteAdapter extends ImageAdapter<ConcreteAdapter>>(
        image: ImageAdapter<ConcreteAdapter>,
        distortion: ReversePixelMapper,
        options: DistortionOptions
    ): ColorResampler {
        if (
            (options.resampler === 'ewa' || options.resampler === true || options.resampler === undefined) &&
            isReversePixelMapperWithEwaSupport(distortion)
        ) {
            return new EllipticalWeightedAverage(
                image,
                distortion,
                this.resolveFilter(options.filter, options.filterBlur, options.filterWindowSupport),
                options.matteColor
            );
        } else {
            return new Point(image, distortion, options.matteColor);
        }
    }

    /**
     * Resolves resample filter for resampler.
     *
     * @param filter Filter option value.
     * @param [blur=1] Filter blur. Ignored if filter is instance of {@link ResampleFilter}
     * @param [windowSupport=null] Filter window support. Ignored if filter is instance of {@link ResampleFilter}
     */
    protected resolveFilter(
        filter: string | FilterName | ResampleFilter | FilterPreset,
        blur: number = 1,
        windowSupport: number | null = null
    ): ResampleFilter {
        if (typeof filter === 'string') {
            return createResampleFilterByPresetName(filter, blur, windowSupport);
        } else if (isFilterPreset(filter)) {
            return createResampleFilterUsingPreset(filter, blur, windowSupport);
        } else if (isResampleFilter(filter)) {
            return filter;
        } else {
            throw new InvalidArgument(
                `Invalid 'filter' option value. Allowed values: filter preset name (string); filter preset definition` +
                `object; object, implementing ResampleFilter interface.`
            );
        }
    }

    /**
     * Sets image adapter's options from given distortion options.
     *
     * @param image Image adapter.
     * @param options Distortion options.
     */
    protected setupImageAdapterOptions<ConcreteAdapter extends ImageAdapter<ConcreteAdapter>>(
        image: ImageAdapter<ConcreteAdapter>,
        options: DistortionOptions
    ): ImageAdapter<ConcreteAdapter> {
        if (options.imageViewportOffset !== null) {
            image.getViewport().offset(...options.imageViewportOffset);
        }

        if (options.imageBackgroundColor !== null) {
            image.setBackgroundColor(options.imageBackgroundColor);
        }

        if (options.imageVirtualPixelMethod !== null) {
            image.setVirtualPixelMethod(options.imageVirtualPixelMethod);
        }

        if (options.imageInterpolationMethod !== null) {
            image.setInterpolationMethod(options.imageInterpolationMethod);
        }

        return image;
    }

    /**
     * Resolves viewport setting for distortion processor from distortion option value.
     *
     * @param viewport {@link DistortionOptions.viewport} value.
     */
    protected resolveViewportOption(
        viewport: Viewport | 'bestFit' | true | null | ViewportLiteral
    ): Viewport | 'bestFit' | true | null {
        if (typeof viewport === 'object') {
            if (viewport instanceof Viewport || viewport === null) {
                return viewport;
            }

            if (isViewportLiteral(viewport)) {
                return Viewport.fromLiteral(viewport);
            }
        }

        return viewport;
    }
}