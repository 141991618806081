import {Viewport} from "../../image/Viewport";
import {PerspectiveMatrix} from "./PerspectiveMatrix";
import {ReversePixelMapperWithEwaSupport} from "../ReversePixelMapperWithEwaSupport";
import {ReversePixelMapperWithBestFit} from "../ReversePixelMapperWithBestFit";
import {ForwardPixelMapper} from "../ForwardPixelMapper";
import {applyPerspectiveMatrix} from "./applyPerspectiveMatrix";
import {invertPerspectiveMatrix} from "./invertPerspectiveMatrix";

/**
 * Perspective Distortion (a ratio of affine distortions).
 *
 * ```
 *     p(x,y)    c0*x + c1*y + c2
 * u = ------ = ------------------
 *     r(x,y)    c6*x + c7*y + 1
 *
 *     q(x,y)    c3*x + c4*y + c5
 * v = ------ = ------------------
 *      r(x,y)    c6*x + c7*y + 1
 * ```
 *
 * denominator = Sign of 'r', or the denominator affine, for the actual image.
 * This determines what part of the distorted image is 'ground'
 * side of the horizon, the other part is 'sky' or invalid.
 * Valid values are  +1.0  or  -1.0  only.
 *
 *
 * @see {@link https://www.imagemagick.org/Usage/distorts/#perspective Perspective distortion details at ImageMagick docs}
 * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l02450 Perspective distortion at ImageMagick source}
 * @category Reverse Pixel Mapper
 */
export class Perspective implements ReversePixelMapperWithEwaSupport, ReversePixelMapperWithBestFit, ForwardPixelMapper {
    /**
     * Reverse matrix.
     */
    readonly matrix: PerspectiveMatrix;

    /**
     * Denominator for mapping validity calculation.
     */
    readonly denominator: number;

    /**
     * Forward matrix.
     */
    readonly forwardMatrix: PerspectiveMatrix;

    /**
     * @inheritDoc
     */
    readonly hasConstantPartialDerivatives: false;

    /**
     *
     * @param reverseMatrix Perspective projection matrix for reverse pixel mapping.
     * @param denominator Sign of 'r', or the denominator affine, for the actual image.
     * This determines what part of the distorted image is 'ground' side of the horizon, the other part is 'sky' or invalid.
     * Valid values are  +1.0  or  -1.0  only.
     */
    constructor(reverseMatrix: PerspectiveMatrix, denominator: number) {
        this.matrix = reverseMatrix;
        this.denominator = denominator;
        this.forwardMatrix = invertPerspectiveMatrix(reverseMatrix);
        this.hasConstantPartialDerivatives = false;
    }

    /**
     * Creates perspective distortion using perspective matrix.
     *
     * @param matrix Perspective matrix.
     * @returns Perspective instance.
     * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l00853 Generating inverted perspective distortion matrix from forward perspective matrix at ImageMagick docs}
     */
    static fromForwardMatrix(matrix: PerspectiveMatrix): Perspective {
        const inverse = invertPerspectiveMatrix(matrix);

        /*
         * Calculate denominator! The ground-sky determination.
         * What is sign of the 'ground' in r() denominator affine function?
         * Just use any valid image coordinate in destination for determination.
         * For a forward mapped perspective the images 0,0 coord will map to
         * c2,c5 in the distorted image, so set the sign of denominator of that.
         */
        const denominator = inverse[6] * matrix[2] + inverse[7] * matrix[5] + 1 < 0 ? -1 : 1;
        return new Perspective(inverse, denominator);
    }

    /**
     * @inheritDoc
     */
    reverseMap(x: number, y: number): [number, number] {
        return applyPerspectiveMatrix(x, y, this.matrix);
    }

    /**
     * @inheritDoc
     */
    getValidity(x: number, y: number, scaling: number): number {
        const r = this.matrix[6] * x + this.matrix[7] * y + 1;
        let validity = r * this.denominator < 0 ? 0 : 1;
        const absR = Math.abs(r) * 2;
        const absC6 = Math.abs(this.matrix[6]);
        const absC7 = Math.abs(this.matrix[7]);

        if (absC6 > absC7) {
            if (absR < absC6) {
                validity = 0.5 - this.denominator * r / (this.matrix[6] * scaling);
            }
        } else if (absR < absC7) {
            validity = 0.5 - this.denominator * r / (this.matrix[7] * scaling);
        }

        return validity;
    }

    /**
     * @inheritDoc
     */
    getPartialDerivatives(x: number, y: number): [number, number, number, number] {
        const p = this.matrix[0] * x + this.matrix[1] * y + this.matrix[2],
            q = this.matrix[3] * x + this.matrix[4] * y + this.matrix[5],
            r = this.matrix[6] * x + this.matrix[7] * y + 1,
            scale = Math.pow(1 / r, 2);

        return [
            (r * this.matrix[0] - p * this.matrix[6]) * scale, // dUx
            (r * this.matrix[1] - p * this.matrix[7]) * scale, // dUy
            (r * this.matrix[3] - q * this.matrix[6]) * scale, // dVx
            (r * this.matrix[4] - q * this.matrix[7]) * scale //dVy
        ];
    }

    /**
     * @inheritDoc
     */
    forwardMap(u: number, v: number): [number, number] {
        return applyPerspectiveMatrix(u, v, this.forwardMatrix);
    }

    /**
     * @inheritDoc
     */
    getBestFitViewport(viewport: Viewport): Viewport {
        const u1 = viewport.x1,
            v1 = viewport.y1,
            u2 = viewport.x2 + 1,
            v2 = viewport.y2 + 1,
            [x, y] = this.forwardMap(u1, v1),
            bestFit = new Viewport(x, y, x, y);

        [[u2, v1], [u2, v2], [u1, v2]].forEach((apex: [number, number]) => bestFit.expand(...this.forwardMap(...apex)));

        bestFit.fixBounds();

        return bestFit;
    }
}