import {ImageAdapter} from "./image/ImageAdapter";
import {Distortion} from "./distortion/Distortion";
import {DistortionOptions} from "./types/DistortionOptions";
import {DistortionResult} from "./types/DistortionResult";
import {ReversePixelMapper} from "./distortion/ReversePixelMapper";
import {Lens} from "./Lens";

/**
 * Distorts image using distortion name and arguments.
 *
 * @param image Image to distort.
 * @param distortion Distortion name.
 * @param args Distortion arguments.
 * @param [options] Distortion options.
 */
export function distort<ConcreteAdapter extends ImageAdapter<ConcreteAdapter>>(
    image: object|ImageAdapter<ConcreteAdapter>|Promise<ImageAdapter<ConcreteAdapter>>,
    distortion: Distortion|string,
    args: number[],
    options?: Partial<DistortionOptions>
): Promise<DistortionResult<ConcreteAdapter>>;

/**
 * Distorts image using distortion object (reverse pixel mapper).
 *
 * @param image Image to distort.
 * @param distortion Prepared distortion object (reverse pixel mapper).
 * @param [options] Distortion options.
 */
export function distort<ConcreteAdapter extends ImageAdapter<ConcreteAdapter>>(
    image: object|ImageAdapter<ConcreteAdapter>|Promise<ImageAdapter<ConcreteAdapter>>,
    distortion: ReversePixelMapper,
    options?: Partial<DistortionOptions>
): Promise<DistortionResult<ConcreteAdapter>>;

export function distort<ConcreteAdapter extends ImageAdapter<ConcreteAdapter>>(
    image: object|ImageAdapter<ConcreteAdapter>|Promise<ImageAdapter<ConcreteAdapter>>,
    distortion: Distortion|string|ReversePixelMapper,
    args?: number[]|Partial<DistortionOptions>,
    options?: Partial<DistortionOptions>
): Promise<DistortionResult<ConcreteAdapter>> {
    // @ts-ignore
    return new Lens().distort<ConcreteAdapter>(image, distortion, args, options);
}