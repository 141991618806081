/**
 * Built-in filter function names.
 *
 * @category Filter Weighting Function
 */
export enum WeightingFunctionName {
    /**
     * A Box filter is an equal weighting function (all weights equal).
     * DO NOT LIMIT results by support or resize point sampling will work
     * as it requests points beyond its normal 0.0 support size.
     */
    BOX = 'Box',

    /**
     * Cubic Filters using B,C determined values:
     * ```
     *     Mitchell-Netravali  B = 1/3 C = 1/3  "Balanced" cubic spline filter
     *     Catmull-Rom         B = 0   C = 1/2  Interpolatory and exact on linears
     *     Spline              B = 1   C = 0    B-Spline Gaussian approximation
     *     Hermite             B = 0   C = 0    B-Spline interpolator
     * ```
     *
     * See paper by Mitchell and Netravali, Reconstruction Filters in Computer
     * Graphics Computer Graphics, Volume 22, Number 4, August 1988
     * {@link http://www.cs.utexas.edu/users/fussell/courses/cs384g/lectures/mitchell/Mitchell.pdf}.
     *
     * Coefficents are determined from B,C values:
     * ```
     *    P0 = (  6 - 2*B       )/6 = coeff[0]
     *    P1 =         0
     *    P2 = (-18 +12*B + 6*C )/6 = coeff[1]
     *    P3 = ( 12 - 9*B - 6*C )/6 = coeff[2]
     *    Q0 = (      8*B +24*C )/6 = coeff[3]
     *    Q1 = (    -12*B -48*C )/6 = coeff[4]
     *    Q2 = (      6*B +30*C )/6 = coeff[5]
     *    Q3 = (    - 1*B - 6*C )/6 = coeff[6]
     * ```
     * which are used to define the filter:
     * ```
     *    P0 + P1*x + P2*x^2 + P3*x^3      0 <= x < 1
     *    Q0 + Q1*x + Q2*x^2 + Q3*x^3      1 <= x < 2
     * ```
     * which ensures function is continuous in value and derivative (slope).
     */
    CUBIC_BC = 'CubicBC',

    /**
     * Not implemented.
     */
    TRIANGLE = 'Triangle', // TODO: implement

    /**
     * Not implemented.
     */
    HANN = 'Hann', // TODO: implement

    /**
     * Not implemented.
     */
    HAMMING = 'Hamming', // TODO: implement

    /**
     * Not implemented.
     */
    BLACKMAN = 'Blackman', // TODO: implement

    /**
     * Not implemented.
     */
    GAUSSIAN = 'Gaussian', // TODO: implement

    /**
     * Not implemented.
     */
    QUADRATIC = 'Quadratic', // TODO: implement

    /**
     * Not implemented.
     */
    JINC = 'Jinc', // TODO: implement

    /**
     * Not implemented.
     */
    SINC = 'Sinc', // TODO: implement

    /**
     * Not implemented.
     */
    SINC_FAST = 'SincFast', // TODO: implement

    /**
     * Not implemented.
     */
    KAISER = 'Kaiser', // TODO: implement

    /**
     * Not implemented.
     */
    WELCH = 'Welch', // TODO: implement

    /**
     * Not implemented.
     */
    BOHMAN = 'Bohman', // TODO: implement

    /**
     * Not implemented.
     */
    LAGRANGE = 'Lagrange', // TODO: implement

    /**
     * Not implemented.
     */
    COSINE = 'Cosine' // TODO: implement
}