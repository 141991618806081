import {InvalidArgument} from "./InvalidArgument";

/**
 * Reserved Name exception. Should be thrown when consumers code trying to register or unregister something under name
 * that is reserved by Lens library.
 *
 * @category Exception
 */
export class ReservedName extends InvalidArgument {
    /**
     * @param message Error message.
     */
    constructor(message: string) {
        super(message);
        this.name = 'ReservedName';
    }
}