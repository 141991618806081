import {ReversePixelMapperWithEwaSupport} from "./ReversePixelMapperWithEwaSupport";
import {isReversePixelMapper} from "./isReversePixelMapper";

/**
 * Checks if object implements ReversePixelMapperWithEwaSupport interface.
 *
 * @param obj
 *
 * @category Reverse Pixel Mapper
 */
export function isReversePixelMapperWithEwaSupport(obj: any): obj is ReversePixelMapperWithEwaSupport {
    return (isReversePixelMapper(obj) as boolean) &&
        'hasConstantPartialDerivatives' in obj && typeof obj.hasConstantPartialDerivatives === 'boolean' &&
        'getPartialDerivatives' in obj && typeof obj.getPartialDerivatives === 'function';
}