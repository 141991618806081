import {AffineMatrix} from "./AffineMatrix";

/**
 * Project coordinates using affine projection matrix.
 *
 * @param x X-coordinate.
 * @param y Y-coordinate.
 * @param matrix Affine matrix.
 * @returns Mapped coords pair.
 */
export function applyAffineMatrix(x: number, y: number, matrix: AffineMatrix): [number, number] {
    return [
        matrix[0] * x + matrix[1] * y + matrix[2],
        matrix[3] * x + matrix[4] * y + matrix[5]
    ];
}