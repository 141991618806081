/**
 * Resample filter presets registry.
 * @module
 * @internal
 */
import {NotFound, NotImplemented, ReservedName} from "../exception/index";
import {InvalidArgument} from "../exception/InvalidArgument";
import {createWeightingFunction} from "../weightingFunction/index";
import {WeightingFunctionName} from "../weightingFunction/WeightingFunctionName";
import {DefaultResampleFilter} from "./DefaultResampleFilter";
import {FilterName} from "./FilterName";
import {FilterPreset} from "./FilterPreset";

/**
 * Filter presets registry.
 */
const registry: Map<string, FilterPreset> = new Map();

// Register inbuilt filter presets
registry.set(
    FilterName.ROBIDOUX,
    {
        filterFunctionFactory: WeightingFunctionName.CUBIC_BC,
        filterFunctionFactoryArgs: [0.37821575509399867, 0.31089212245300067], // [b, c]
        windowFunctionFactory: WeightingFunctionName.BOX,
        windowFunctionFactoryArgs: [],
        support: 2,
        scale: 1.1685777620836932
    });

registry.set(
        FilterName.ROBIDOUX_SHARP,
        {
            filterFunctionFactory: WeightingFunctionName.CUBIC_BC,
            filterFunctionFactoryArgs: [0.2620145123990142, 0.3689927438004929], // [b, c]
            windowFunctionFactory: WeightingFunctionName.BOX,
            windowFunctionFactoryArgs: [],
            support: 2,
            scale: 1.105822933719019
        }
    );

registry.set(
    FilterName.BOX,
    {
        filterFunctionFactory: WeightingFunctionName.BOX,
        filterFunctionFactoryArgs: [],
        windowFunctionFactory: WeightingFunctionName.BOX,
        windowFunctionFactoryArgs: [],
        support: 0.5,
        scale: 0.5
    }
)

// TODO add more filter presets

/**
 * Checks if given name is reserved for inbuilt filter presets.
 *
 * @param name
 *
 * @category Resample Filter
 */
export function isReservedFilterPresetName(name: string): boolean {
    return Object.values<string>(FilterName).includes(name);
}

/**
 * Checks if filter preset under given name is registered.
 *
 * @param name Preset name.
 *
 * @category Resample Filter
 */
export function isRegisteredFilterPresetName(name: string): boolean {
    return registry.has(name);
}

/**
 * Registers filter preset under given name.
 *
 * @param name Filter preset name.
 * @param preset Filter preset object.
 * @param [replace=false] Replace existing preset under given name if any.
 * @throws {@link InvalidArgument} When trying to register preset under name which is already registered and not forced
 * to be replaced.
 * @throws {@link ReservedName} When trying to register preset under reserved name.
 *
 * @category Resample Filter
 */
export function registerFilterPreset(name: string, preset: FilterPreset, replace: boolean = false): void {
    if (isReservedFilterPresetName(name)) {
        throw new ReservedName(`Filter preset name '${name}' is reserved.`);
    }

    if (isRegisteredFilterPresetName(name) && !replace) {
        throw new InvalidArgument(
            `Filter preset name '${name}' is already registered. Choose another name or set 'replace' param to true.`
        );
    }

    registry.set(name, preset);
}

/**
 * Unregisters filter preset under given name.
 *
 * @param name Preset name.
 * @throws {@link ReservedName} When trying to unregister preset under reserved name.
 *
 * @category Resample Filter
 */
export function unregisterFilterPreset(name: string): void {
    if (isReservedFilterPresetName(name)) {
        throw new ReservedName(`Can't unregister filter preset '${name}', because it's reserved.`);
    }

    if (isRegisteredFilterPresetName(name)) {
        registry.delete(name);
    }
}

/**
 * Returns filter preset, registered under given name.
 *
 * @param name Filter preset name.
 * @throws {@link NotFound} When preset under given name can't be found.
 * @throws {@link NotImplemented} When preset under given reserved name is not implemented.
 *
 * @category Resample Filter
 */
export function getFilterPreset(name: string): FilterPreset {
    if (isRegisteredFilterPresetName(name)) {
        const preset = registry.get(name);
        if (preset) {
            return preset;
        }
    } else if (isReservedFilterPresetName(name)) {
        throw new NotImplemented(`Filter preset ${name} is not implemented yet.`);
    }

    throw new NotFound(`Filter preset '${name}' was not found.`);
}

/**
 * Creates resample filter using filter preset.
 *
 * @param preset Filter preset.
 * @param [blur=1] X-scale (blur-sharpen).
 * @param [windowSupport=null] Window support, usually equal to support (expert only).
 * @throws {@link NotFound}
 * @throws {@link NotImplemented}
 *
 * @category Resample Filter
 */
export function createResampleFilterUsingPreset(
    preset: FilterPreset,
    blur: number = 1,
    windowSupport: number | null = null
): DefaultResampleFilter {
    return new DefaultResampleFilter(
        createWeightingFunction(preset.filterFunctionFactory, preset.filterFunctionFactoryArgs),
        createWeightingFunction(preset.windowFunctionFactory, preset.windowFunctionFactoryArgs),
        preset.support,
        preset.scale,
        blur,
        windowSupport
    );
}

/**
 * Creates resample filter using filter preset name.
 *
 * @param presetName Name of filter preset.
 * @param [blur=1] X-scale (blur-sharpen).
 * @param [windowSupport=null] Window support, usually equal to support (expert only).
 * @throws {@link InvalidArgument}
 *
 * @category Resample Filter
 */
export function createResampleFilterByPresetName(
    presetName: string,
    blur: number = 1,
    windowSupport: number | null = null
): DefaultResampleFilter {
    const preset = getFilterPreset(presetName);
    return createResampleFilterUsingPreset(preset, blur, windowSupport);
}