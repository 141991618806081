import {AffineMatrix} from "./AffineMatrix";
import {InvalidArgument} from "../../exception";
import {perceptibleReciprocal} from "../../math/perceptibleReciprocal";

/**
 * Creates inverted affine matrix from affine matrix.
 *
 * @param matrix Affine matrix.
 * @returns Inverted affine matrix.
 * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l00095 Inverting affine matrix at ImageMagick source}
 */
export function invertAffineMatrix(matrix: AffineMatrix): AffineMatrix {
    /* From "Digital Image Warping" by George Wolberg, page 50 */

    const determinant = perceptibleReciprocal(matrix[0] * matrix[4] - matrix[1] * matrix[3]);

    if (!determinant) {
        throw new InvalidArgument("Given matrix can't be inverted");
    }

    return [
        determinant * matrix[4],
        determinant * (-matrix[1]),
        determinant * (matrix[1] * matrix[5] - matrix[2] * matrix[4]),
        determinant * (-matrix[3]),
        determinant * matrix[0],
        determinant * (matrix[2] * matrix[3] - matrix[0] * matrix[5])
    ];
}