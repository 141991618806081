/**
 * Internal storage for bindings constructors to image adapter factories.
 * @module
 * @internal
 */
import {NotFound} from "../exception/index";
import {ImageAdapterFactory} from "./ImageAdapterFactory";
import {ImageAdapter} from "./ImageAdapter";

/**
 * Bindings map.
 */
const bindings: Map<Function, ImageAdapterFactory<any>> = new Map();

/**
 * Binds image adapter factory to corresponding resource constructor.
 *
 * @param constructor Image resource constructor (e.g. HTMLImageElement, HTMLCanvasElement, OffscreenCanvas...)
 * @param factory Image adapter factory.
 *
 * @category Image Adapter
 */
export function bindImageAdapterFactory<ConcreteAdapter extends ImageAdapter<ConcreteAdapter> = ImageAdapter<any>>(
    constructor: Function,
    factory: ImageAdapterFactory<ConcreteAdapter>
): void {
    bindings.set(constructor, factory);
}

/**
 * Unbinds given image adapter factory from given resource constructor.
 * 
 * @param constructor Image resource constructor.
 *
 * @category Image Adapter
 */
export function unbindImageAdapterFactory(constructor: Function): void {
    if (bindings.has(constructor)) {
        bindings.delete(constructor);
    }
}

/**
 * Resolves {@link ImageAdapter} instance for given resource object if it's constructor has corresponding
 * factory binding.
 * @param resource Image resource object (e.g. HTMLImageElement, HTMLCanvasElement or any other instance of previously
 * bound class).
 * @throws {@link NotFound}
 *
 * @category Image Adapter
 */
export function resolveImageAdapterForResource<ConcreteAdapter extends ImageAdapter<ConcreteAdapter> = ImageAdapter<any>>(
    resource: object
): ImageAdapter<ConcreteAdapter>|Promise<ImageAdapter<ConcreteAdapter>> {
    if (bindings.has(resource.constructor)) {
        const binding = bindings.get(resource.constructor);

        if (binding) {
            return binding(resource);
        }
    }

    // Assume that resource may be subclass of constructor
    let foundFactory: ImageAdapterFactory<ConcreteAdapter>|null = null;
    bindings.forEach((factory: ImageAdapterFactory<ConcreteAdapter>, constructor: Function) => {
        if (resource instanceof constructor) {
            foundFactory = factory;
        }
    });

    if (foundFactory !== null) {
        return (foundFactory as ImageAdapterFactory<ConcreteAdapter>)(resource);
    }

    throw new NotFound(`Couldn't find image adapter factory for given resource.`);
}