import {Affine} from "./Affine";
import {InvalidArgumentsLength} from "../../exception";
import {LeastSquaresSolver} from "../../math";
import {AffineMatrix} from "./AffineMatrix";

/**
 * Creates affine distortion using control points array.
 *
 * @param controlPoints Mappings of control points [u0, v0, x0, y0, ... , un, vn, xn, yn] where
 * (u*, v*) are source (x, y) point and (x*, y*) are destination (x, y) point.
 * @returns Affine instance.
 * @see {@link https://imagemagick.org/api/MagickCore/distort_8c_source.html#l00501 Generating affine distortion matrix from control points at ImageMagick source}
 * @category Reverse Pixel Mapper Factory
 */
export function AffineFactory(controlPoints: number[]): Affine {
    if (!controlPoints.length || controlPoints.length % 4 !== 0) {
        throw new InvalidArgumentsLength(
            `Number of arguments must be multiple of 4 and at least 4 arguments (1 control point) expected.` +
            `${controlPoints.length} arguments given.`
        );
    }

    /*
     * handle special cases of not enough arguments
     */
    if (controlPoints.length === 4) {
        // Only 1 CP Set Given
        return new Affine([
            1, 0, controlPoints[0] - controlPoints[2],
            0, 1, controlPoints[1] - controlPoints[3]
        ]);
    } else {
        // 2 or more points (usually 3) given.
        // Solve a least squares simultaneous equation for coefficients.
        const leastSquares = new LeastSquaresSolver(3, 2);

        for (let i = 0; i < controlPoints.length; i += 4) {
            let [u, v, x, y] = controlPoints.slice(i, i + 4);

            leastSquares.addTerms([
                x, y, 1
            ], [u, v]);
        }

        if (controlPoints.length === 8) {
            /*
             * Only two pairs were given, but we need 3 to solve the affine.
             * Fake extra coordinates by rotating p1 around p0 by 90 degrees.
             * x2 = x0 - (y1-y0)   y2 = y0 + (x1-x0)
             */
            leastSquares.addTerms(
                [
                    controlPoints[2] - (controlPoints[7] - controlPoints[3]),
                    controlPoints[3] + (controlPoints[6] - controlPoints[2]),
                    1
                ],
                [
                    controlPoints[0] - controlPoints[5] + controlPoints[1],
                    controlPoints[1] + controlPoints[4] - controlPoints[0]
                ]
            );
        }

        const vectors = leastSquares.getVectors();

        return new Affine(
            (vectors[0].concat(vectors[1])) as AffineMatrix
        );
    }
}