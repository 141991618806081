import {InvalidArgument} from "./InvalidArgument";

/**
 * Exception for not found entities resolution.
 *
 * @category Exception
 */
export class NotFound extends InvalidArgument {
    /**
     * @param message Error message.
     */
    constructor(message: string) {
        super(message);
        this.name = 'NotFound';
    }
}