/**
 * Virtual pixel methods.
 *
 * @see {@link https://www.imagemagick.org/Usage/misc/#virtual-pixel Virtual pixel details} at ImageMagick docs.
 *
 * @category Image Adapter
 */
export enum VirtualPixelMethod {
    /**
     * Use image background color.
     */
    BACKGROUND = 1,

    /**
     * Dither virtual pixel method. (Not implemented, will throw NotImplemented exception)
     */
    DITHER = 2, // TODO: implement

    /**
     * Closest edge color.
     */
    EDGE = 3,

    /**
     * Mirror effect.
     */
    MIRROR = 4,

    /**
     * Random color from image.
     */
    RANDOM = 5,

    /**
     * Tile effect.
     */
    TILE = 6,

    /**
     * Transparent color.
     */
    TRANSPARENT = 7,

    /**
     * Black color.
     */
    BLACK = 9,

    /**
     * Gray color.
     */
    GRAY = 10,

    /**
     * White color.
     */
    WHITE = 11,

    /**
     * Tile effect horizontally, background color vertically.
     */
    HORIZONTAL_TILE = 12,

    /**
     * Tile effect vertically, background color horizontally.
     */
    VERTICAL_TILE = 13,

    /**
     * Tile effect horizontally, closest edge color vertically.
     */
    HORIZONTAL_TILE_EDGE = 14,

    /**
     * Tile effect vertically, closest edge color horizontally.
     */
    VERTICAL_TILE_EDGE = 15,

    /**
     * Checker tile.  (Not implemented, will throw NotImplemented exception)
     */
    CHECKER_TILE = 16 // TODO: implement
}