/**
 * Out of the box supported distortions.
 *
 * @category Reverse Pixel Mapper
 */
export enum Distortion {
    /**
     * Affine distortion using control points. Arguments are sets of control points mappings:
     * [u0, v0, x0, y0, ..., uN, vN, xN, yN], where u*, v* are source image coords, x*, y* are destination image coords.
     */
    AFFINE = 'Affine',

    /**
     * Affine distortion using forward affine matrix. Arguments are affine matrix coefficients: [sx, ry, rx, sy, tx, ty].
     * *IMPORTANT NOTE: Arguments order differs from {@link Affine.fromForwardMatrix} arguments order which is
     * [sx, rx, tx, ry, sy, ty]*
     */
    AFFINE_PROJECTION = 'AffineProjection',

    /**
     * Perspective distortion using control points. Arguments are sets of control points mappings:
     * [u0, v0, x0, y0, ..., uN, vN, xN, yN], where u*, v* are source image coords, x*, y* are destination image coords.
     */
    PERSPECTIVE = 'Perspective',

    /**
     * Perspective distortion using forward perspective matrix.
     * Arguments are perspective matrix coefficients: [sx, ry, tx, rx, sy, ty, px, py].
     */
    PERSPECTIVE_PROJECTION = 'PerspectiveProjection',

    /**
     * Arc distortion. Arguments are: [arcAngle, rotation, outerRadius, innerRadius].
     * All arguments except arcAngle are optional.
     */
    ARC = 'Arc',

    /**
     * Not implemented.
     */
    SCALE_ROTATE_TRANSLATE = 'ScaleRotateTranslate', // TODO: implement

    /**
     * Not implemented.
     */
    BILINEAR_FORWARD = 'BilinearForward', // TODO: implement

    /**
     * Not implemented.
     */
    BILINEAR_REVERSE = 'BilinearReverse', // TODO: implement

    /**
     * Not implemented.
     */
    POLYNOMIAL = 'Polynomial', // TODO: implement

    /**
     * Not implemented.
     */
    POLAR = 'Polar', // TODO: implement

    /**
     * Not implemented.
     */
    DE_POLAR = 'DePolar', // TODO: implement

    /**
     * Not implemented.
     */
    CYLINDER_TO_PLANE = 'Cylinder2Plane', // TODO: implement

    /**
     * Not implemented.
     */
    PLANE_TO_CYLINDER = 'Plane2Cylinder', // TODO: implement

    /**
     * Not implemented.
     */
    BARREL = 'Barrel', // TODO: implement

    /**
     * Not implemented.
     */
    BARREL_INVERSE = 'BarrelInverse', // TODO: implement

    /**
     * Not implemented.
     */
    SHEPARDS = 'Shepards' // TODO: implement
}