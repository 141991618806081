import {DistortionOptions} from "./types/DistortionOptions";
import {Lens} from "./Lens";

/**
 * Created {@link Lens} instance with given options set as defaults.
 *
 * @param options
 */
export function createInstance(options: Partial<DistortionOptions>): Lens {
    return new Lens(options);
}