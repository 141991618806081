import {LensException} from './LensException';

/**
 * Exception for cases when invalid number of arguments passed.
 *
 * @category Exception
 */
export class InvalidArgumentsLength extends LensException {
    /**
     * @param message Error message.
     */
    constructor(message: string) {
        super(message);
        this.name = 'InvalidArgumentsLength';
    }
}